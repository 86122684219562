@import '../../vars';

.RatingsLayout {
  width: 100%;
  grid-template-columns: 100%;
  //grid-template-rows: auto 1fr;//minmax(0, 1fr);
}
.Ratings {
  grid-row: 1 / span 1;
  overflow: hidden;
}
.Ratings__scroll {
  max-height: 100%;
  padding: 24px var(--grid-col-gutter) 32px;
  overflow: auto;
}

.Ratings__options {
  display: flex;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.Ratings__search {
  height: 48px;
  margin-bottom: 24px;
  max-width: 584px;
}

.Ratings__select {
  :global .select__control {
    height: 48px;
  }
}

.Ratings__selectFo {
  width: 176px;
}

.Ratings__tableContainer {
  margin-left: calc(-1 * var(--grid-col-gutter));
  margin-right: calc(-1 * var(--grid-col-gutter));
  padding-left: var(--grid-col-gutter);
  padding-right: var(--grid-col-gutter);
}

.Ratings__tableWRapper {
}
.Ratings__tableInner {
  position: relative;
  @extend %flex;
  align-items: flex-start;
}
.Ratings__tablePositions {
  //white-space: nowrap;

  .Ratings__table {
    th:nth-child(2) {
      text-align: left;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .Table__cell {
    &:first-child {
      width: 22px;
      min-width: 22px;
      padding-right: 16px;
    }
    &:nth-child(2) {
      width: 180px;
      min-width: 180px;
      padding-right: 8px;
    }
  }
}
.Ratings__tableContent {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;

  .Table__cell {
    text-align: center;
  }
}
.Ratings__table {
  table-layout: fixed;
  border-collapse: collapse;
  min-width: 100%;

  thead {
    tr {
      &:first-child {
        height: 110px;
      }
    }
  }
  tbody {
    tr {
      height: 48px;
      &:last-child {
        td._active {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }
  }
}

.Table__cell {
  width: 72px;
  padding: 12px 0;
  background-color: inherit;
  transition: background-color 0.5s;

  &._active {
    background-color: $col-white;
  }

  .Table__tag {
    width: 32px;
    line-height: 24px;
    padding: 0;
    text-align: center;
  }
}
.Table__buttonCell {
  padding: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  text-align: center;
  vertical-align: bottom;
  //vertical-align: middle;
}

.SortButton {
  position: relative;
  width: 100%;
  padding: 12px 0;
  padding-right: 24px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font-family: $fonts-default;
  text-align: right;
  color: $col-gray-d;
  transition: background-color 0.2s;

  &:hover,
  &:active,
  &:focus {
    .SortButton__title {
      color: $col-blue-d2;
    }
    .SortButton__icon {
      color: $col-gray-d;
    }
    &:not(._active) {
      border-radius: 2px;
      background-color: rgba($col-blue-d, 0.1);
    }
  }
  &._active {
    .SortButton__icon {
      color: $col-gray-d;
    }
  }
  &._up {
    .SortButton__icon {
      transform: rotate(360deg);
    }
  }
}

.SortButton__title {
  margin: 0;
  padding: 0 8px;
  font-size: 12px;
  line-height: 1.33;
  font-weight: 700;
  transition: color 0.2s linear, background-color 0.05s linear;
}

.SortButton__icon {
  position: absolute;
  bottom: 0;
  right: 0;
  //display: inline-block;
  width: 24px;
  height: 24px;
  margin-bottom: 46px;
  color: rgba($col-gray-d, 0.3);
  transform: rotate(180deg);
  transition: color 0.2s linear, transform 0.2s linear;
}
.SortButton__actualityDate {
  font-size: 12px;
  padding: 0 8px;
}
.Link {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;
  &:hover {
    color: $col-blue;
  }
  &:active,
  &:focus {
    color: lighten($col-blue, 10%);
  }
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .Ratings__options {
      flex-wrap: wrap;
      margin-bottom: 32px;

      > * {
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 4;
          margin-right: 0;
        }
      }

      > form {
        order: 1;
        min-width: 100%;
        margin-right: 0;
      }
    }

    .Ratings__tableContainer {
      padding-right: 0;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .RatingsLayout {
      //grid-template-rows: 1fr;//minmax(0, 1fr);
    }
    .Ratings {
      padding-bottom: var(--nav-bar-height);
    }
    .Ratings__scroll {
      padding-bottom: 0;
    }

    .Ratings__search {
      min-width: 0;
    }
    .Ratings__select {
      width: auto;
      flex: 1;
    }

    .Ratings__tableContainer {
      padding-left: 0;
    }
    .Ratings__tablePositions {
      .Table__cell {
        font-size: 14px;
        &:first-child {
          //width: 36px;
          //min-width: 36px;
          width: auto;
          min-width: 0;
          padding-left: 8px;
          padding-right: 8px;
        }
        &:nth-child(2) {
          width: 100px;
          min-width: 100px;
        }
      }
    }
  }
  @media (max-width: $bp-mobile-small-max-width) {
    .Ratings__options {
      > * {
        min-width: 100%;
        margin-bottom: 24px;
        &:nth-child(3) {
          margin-bottom: 0;
        }
      }
    }
    .Ratings__search {
      margin-bottom: 0;
    }
    .Ratings__select {
      margin-right: 0;
    }
  }

  @media (min-width: $bp-tablet-land-min-width) {
    .Ratings__options {
      flex-wrap: wrap;
      margin-bottom: 32px;

      > * {
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 4;
        }
      }

      > form {
        order: 1;
        margin-right: 0;
        min-width: 100%;
      }
    }
    .Ratings__search {
      min-width: 0;
    }

    .SortButton {
      padding-left: 8px;
      //padding-right: 8px;
    }
  }

  @media (min-width: $bp-sm-desktop-min-width) {
    .Ratings__options {
      > * {
        //flex: 1;
      }
      > form {
        min-width: 350px;
        margin-right: 16px;
      }
    }
  }
}
