@import '../../../colors';

.StackedBarChart {
  width: 100%;
  height: 100%;
  position: relative;

  &._padding {
    padding-top: 24px;
  }
}

.StackedBarChart__legend {
  width: 100%;
  top: 0;
  position: absolute;
}

.StackedBarChart__legendItem {
  position: absolute;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
}

:export {
  defaultColor: rgba(50, 80, 187, 1);
  defaultTextColor: $col-black;
}
