@import '../../vars';

.RightPaneLayout {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: minmax(0, 1fr) var(--right-pane-width);
  height: 100%;

  &._topbar {
    padding-top: var(--top-bar-height);
  }
}

.RightPaneLayout__main {
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  //height: 100%;
  //overflow: hidden;
  //overflow-y: scroll;

  &._fixed {
    .RightPaneLayout__mainScroll {
      padding-bottom: 0;
      overflow-y: auto;
    }
  }
}
.RightPaneLayout__mainScroll {
  height: 100%;
  //overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: var(--page-content-vert-padd) var(--page-content-side-padd)
    var(--page-content-bottom-padd);
}

.RightPaneLayout__right {
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
  //height: 100%;
}
.RightPaneLayout__rightScroll {
  height: 100%;
  //overflow: hidden;
  overflow-y: scroll;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .RightPaneLayout {
      grid-template-columns: 100%;
    }
    .RightPaneLayout__mainScroll {
      padding-bottom: calc(var(--button-height) + 20px) !important;
    }
    .RightPaneLayout__right {
      display: none;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .RightPaneLayout__main {
      height: 100%;
    }
    .RightPaneLayout__mainScroll {
      padding-bottom: calc(
        var(--nav-bar-height) + var(--button-height) + 20px
      ) !important;
    }
  }
}
