@import '../../vars';
@import '../../colors';

.ObjectMarkerIcon {
  width: 32px;
  height: 39px;
  position: relative;
  color: white;
}

.ObjectMarkerIcon_internet {
  width: 32px;
  height: 39px;
  position: relative;
  color: white;
  .ObjectMarkerIcon__text {
    flex-direction: column;
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.ObjectMarkerIcon__svg {
  width: 32px;
  height: 32px;
  color: $col-success;
}

.ObjectMarkerIcon__orgType {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
  color: $col-white;
  & img {
    width: 22px;
    height: 24px;
    padding-bottom: 2px;
  }
}

.ObjectMarkerIcon__text {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  text-align: center;
  width: 100%;
  height: 32px;
  font-family: $fonts-default;
  font-size: 10px;
  font-weight: 700;
  left: 0;
  top: 0;
}

.ObjectClusterIcon {
  width: 30px;
  height: 30px;
  position: relative;
  color: $col-text-d;
  border-radius: 50%;
  background-color: white;
}

.InternetClusterIcon {
  width: 40px;
  height: 40px;
  position: relative;
  color: $col-text-d;
  border-radius: 50%;
  background-color: white;
}

.InternetClusterIcon__speedIcon {
  width: 4px;
  height: 4px;
}

.InternetClusterIcon__download {
  transform: rotate(180deg);
}

.ObjectClusterIcon__chart {
  width: 100%;
  height: 100%;
}

.CountIcon {
  width: 46px;
  height: 46px;
}

.CountIcon_internet {
  width: 38px;
  height: 38px;
}

.ObjectClusterIcon__text {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: $fonts-default;
  font-size: 10px;
  font-weight: 700;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ObjectClusterIcon__internet {
  font-size: 8px;
  line-height: 8px;
  font-weight: 700;
  flex-direction: column;
}

.ObjectClusterIcon__download {
  color: $col-gray-d;
}

.div_ObjectClusterIcon {
  background: none;
  border: 0;
}
