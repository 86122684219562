@import '../../../colors';

.Purchases {
  margin-top: 55px;
}
.Purchases__title {
  margin-bottom: 26px;
}
.Purchases__chartView {
  position: relative;
  width: 100%;
  height: 256px;
}

.Chart1 {
  position: absolute;
  top: 0;
  left: 0;
}

.Chart2 {
  position: absolute;
  bottom: 0;
  left: calc(50% - 8px);
}

.Chart3 {
  position: absolute;
  right: 0;
  top: 0;
}

.Purchases__pie {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  path {
    pointer-events: auto;
  }
}

.Purchases__label {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: absolute;
  right: calc(50% + 8px);
}

.Purchases__label__title {
  font-size: 12px;
  color: $col-gray-d;
  margin-right: 12px;
  margin-top: -8px;
}

.Purchases__label__value {
  margin-top: -8px;
  line-height: 16px;
}

.Purchases__total {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.Purchases__totalData {
  text-align: center;
}

.Purchases__total__title {
  margin-top: 4px;
  font-size: 12px;
  color: $col-gray-d;
  pointer-events: auto;
}

.Purchases__total__value {
  font-size: 32px;
  pointer-events: auto;
}
