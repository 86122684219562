@import '../../../vars';

.ObjectAbout {
  //max-width: 246px;
}
.ObjectAbout__title {
  margin-bottom: 26px;
  p {
    font-weight: 700;
    line-height: 24px;
    margin: 0;
  }
}

.ObjectAbout__persons {
  margin-top: 40px;
}
.ObjectAbout__person {
  margin-bottom: 34px;
  &:last-child {
    margin-bottom: 0;
  }
}
.ObjectAbout__personLabel {
  margin-bottom: 4px;
  color: $col-gray-d;
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
.ObjectAbout__personName {
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.ObjectAbout__actualDate {
  margin-top: 12px;
  opacity: 0.6;
  color: $col-gray-d;
  font-size: 11px;
  line-height: 16px;
}

//@media only screen {
//  @media (min-width: $bp-desktop-min-width) {
//
//    .ObjectAbout {
//      //max-width: none;
//    }
//
//  }
//}
