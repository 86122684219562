@import '../../vars';

.Popup {
  --popup-padding-side: 48px;
  --popup-padding-bottom: 24px;

  position: absolute;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 8px;
  padding: 32px var(--popup-padding-side) var(--popup-padding-bottom)
    var(--popup-padding-side);
  outline: none;
  border: none;
}

.PopupOverlay {
  z-index: var(--zindex-map-filters-popup);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($col-black, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.CloseButton {
  position: absolute;
  top: 24px;
  right: 16px;
}
