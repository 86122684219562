@import '../../vars';

.PageOffset {
  position: relative;
  @extend %flex-c;
  height: 100%;
}

.PageLayout {
  padding-top: var(--top-bar-height);
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  width: 100%;
}
._with-column {
  grid-template-columns: auto var(--right-pane-width);
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    ._with-column {
      grid-template-columns: 100%;
    }
  }
}
