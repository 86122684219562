@import '../../../colors';
@import '../../../vars';

%opacity-0 {
  fill-opacity: 0;
  stroke-opacity: 0;
}

.LineChart__rect._active {
  // todo градиент как в дизайне
  fill-opacity: 0.04;
}

.LineChart__rect,
.LineChart__line {
  @extend %opacity-0;
}

.LineChart__bar {
  fill: black;
  transition: fill-opacity 0.1s, stroke-opacity 0.1s;

  &:hover {
    .LineChart__line {
      stroke-opacity: 1;
    }
    .circleHidden {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
}

.LineChart__circle {
  fill: white;
}

.circleHidden {
  fill-opacity: 0;
  stroke-opacity: 0;
}
