@import '../../../vars';
@import '../../../colors';

$col-primary-hover: darken($col-light-background, 3%);
$col-secondary: rgb(70, 97, 193);
$col-secondary-hover: lighten($col-secondary, 3%);

.Select {
  position: relative;
  width: 330px;
  font-size: 14px;

  --height: 56px;

  .Select__check {
    position: absolute;
    top: 0;
    width: 20px;
    right: 16px;
    height: 56px;
  }

  .Select__content {
    //white-space: nowrap;
    width: 100%;

    height: 100%;
    padding: 20px 0;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Select__multiCount {
    margin: 0 2px 0 0;
    font-size: 14px;
    line-height: 18px;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Select__input {
    input {
      font-family: inherit !important;
      font-size: 14px !important;
      line-height: 18px;
    }
  }

  :global {
    .select__single-value {
      color: inherit;
    }
    .select__control {
      height: var(--height);
      border: none;
      box-shadow: none;
      border-radius: 8px;
      padding-left: 6px;
      cursor: pointer;
    }
    .select__value-container > div {
      margin: 0;
      padding-top: 0;
      padding-bottom: 2px;
      color: inherit;
    }
    .select__control--is-focus {
      border: none;
      box-shadow: none;
    }
    .select__control--is-disabled {
      opacity: 0.5;
    }
    .select__indicator-separator {
      display: none;
    }
    .select__option {
      min-height: var(--height);
      display: flex;
      align-items: center;
      font-family: $fonts-default;
      font-size: 14px;
      padding: 0 40px 0 20px;
      line-height: var(--height);
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .select__option--is-selected {
      position: relative;
    }
    .select__menu-list {
      border-radius: 8px;
      padding: 0;
      min-width: 140px;
    }
    .select__menu {
      z-index: var(--zindex-select-menu);
      padding: 0;
      border-radius: 8px;
    }
    .select__placeholder,
    .select__single-value {
      font-size: 14px;
    }
    .select__single-value {
      margin: 0;
    }
    .select__placeholder {
      opacity: 0.5;
      transition: top 0.1s, font-size 0.1s;
    }
    .select__value-container {
      position: relative;
      &.has-placeholder {
        &.select__value-container--has-value,
        &.has-input-value {
          padding-top: 16px;
          .select__single-value {
            transform: none;
          }
          .select__placeholder {
            margin: 0;
            top: 10px;
            font-size: 12px;
          }
        }
      }
    }
  }

  &._primary {
    .Select__check {
      color: $col-chart-blue;
    }
    .Select__dropDown {
      color: $col-gray-d;
      transition: color 0.2s;
      &:hover {
        color: $col-blue-d;
      }
      &:active,
      &:focus {
        color: $col-blue-d;
      }
    }
    .Select__clearable {
      color: hsl(0, 0%, 60%);
    }

    :global {
      .select__control {
        background-color: $col-light-background;
      }
      .select__control {
        &:hover,
        &--is-focused {
          background-color: $col-primary-hover;
        }
      }
      .select__control--menu-is-open {
        background-color: $col-primary-hover;
      }
      .select__menu {
        background: none;
        border: none;
        box-shadow: none;
      }
      .select__option {
        background-color: $col-light-background;
        color: $col-gray-d;
      }
      .select__placeholder {
        color: $col-gray-d;
      }
      .select__menu-notice {
        background-color: $col-light-background;
        color: $col-blue-d;
      }
      .select__option {
        background-color: $col-light-background;
        color: $col-blue-d;
      }
      .select__option + .select__option {
        border-top: 1px solid rgba($col-gray-d, 0.3);
      }
      .select__option--is-focused {
        background-color: darken($col-light-background, 3%);
      }
    }
  }

  &._secondary {
    .Select__check {
      color: $col-white;
    }
    .Select__dropDown {
      color: rgba($col-white, 0.6);
      transition: color 0.2s;
      &:hover {
        color: $col-white;
      }
      &:active,
      &:focus {
        color: $col-white;
      }
    }
    .Select__clearable {
      color: hsl(0, 0%, 90%);
    }
    :global {
      .select__control {
        background-color: $col-secondary;
      }
      .select__single-value {
        color: $col-white;
      }
      .select__control {
        &:hover,
        &--is-focused {
          background-color: $col-secondary-hover;
        }
      }
      .select__control--menu-is-open {
        background-color: $col-secondary-hover;
      }
      .select__placeholder {
        color: $col-white;
      }
      .select__input {
        color: $col-white;
      }
      .select__menu {
        background: none;
        border: none;
        box-shadow: none;
      }
      .select__option {
        background-color: $col-secondary;
        color: $col-white;
      }
      .select__menu-notice {
        background-color: $col-secondary;
        color: $col-white;
      }
      .select__option + .select__option {
        :local .Select__content {
          border-top: 1px solid rgba($col-white, 0.3);
        }
      }
      .select__option--is-focused {
        background-color: $col-secondary-hover;
      }
    }
  }

  &._darker {
    .Select__check {
      color: $col-chart-blue;
    }
    .Select__dropDown {
      color: $col-gray-d;
      transition: color 0.2s;
      &:hover {
        color: $col-blue-d;
      }
      &:active,
      &:focus {
        color: $col-blue-d;
      }
    }
    .Select__clearable {
      color: hsl(0, 0%, 60%);
    }

    :global {
      .select__control {
        background-color: $col-darker;
      }
      .select__control {
        &:hover,
        &--is-focused {
          background-color: darken($col-darker, 3%);
        }
      }
      .select__control--menu-is-open {
        background-color: darken($col-darker, 3%);
      }
      .select__menu {
        background: none;
        border: none;
        box-shadow: none;
      }
      .select__option {
        background-color: $col-darker;
        color: $col-gray-d;
      }
      .select__placeholder {
        color: $col-gray-d;
      }
      .select__menu-notice {
        background-color: $col-darker;
        color: $col-blue-d;
      }
      .select__option {
        background-color: $col-darker;
        color: $col-blue-d;
      }
      .select__option + .select__option {
        border-top: 1px solid rgba($col-gray-d, 0.3);
      }
      .select__option--is-focused {
        background-color: darken($col-light-background, 7%);
      }
    }
  }

  &._menu {
    .Select__check {
      color: $col-chart-blue;
    }
    .Select__dropDown {
      color: inherit;
      transition: color 0.2s;
      &:hover {
        color: inherit;
      }
    }
    .Select__clearable {
      color: inherit;
    }

    :global {
      .select__control {
        background: none;
      }
      .select__control {
        &:hover,
        &--is-focused {
          background: none;
        }
      }
      .select__dropdown-indicator {
        color: inherit;

        &:hover {
          color: inherit;
        }
        &:active,
        &:focus {
          color: inherit;
        }
      }
      .select__single-value {
        font-size: inherit;
        color: inherit;
      }
      .select__control--menu-is-open {
        background: none;
      }
      .select__menu {
        background: none;
        border: none;
        box-shadow: none;
      }
      .select__option {
        background-color: $col-light-background;
        color: $col-gray-d;
      }
      .select__placeholder {
        color: $col-gray-d;
      }
      .select__menu-notice {
        background-color: $col-light-background;
        color: $col-blue-d;
      }
      .select__option {
        background-color: $col-light-background;
        color: $col-blue-d;
      }
      .select__option + .select__option {
        border-top: 1px solid rgba($col-gray-d, 0.3);
      }
      .select__option--is-focused {
        background-color: darken($col-light-background, 3%);
      }
    }
  }

  &._small {
    --height: 40px;
  }
}
