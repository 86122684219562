@import '../../../colors';
@import '../../../vars';

$col-secondary-hover: rgb(233, 234, 237);

.Title__header {
  display: flex;
  //margin-top: 24px;
}

.Title__title {
  font-size: 28px;
  line-height: 40px;
}

.Title__name {
  white-space: pre-line;
}

.Title__icon {
  color: $col-gray-d;
  display: inline;
  vertical-align: top;
}

.popup {
  padding: 0 16px;
  min-height: 80%;
  h1 {
    font-size: 18px;
    line-height: 1.4;
  }
  p {
    line-height: 1.4;
  }
}

.Title__backIcon {
  flex-basis: 32px;
  min-width: 32px;
  height: 40px;
  margin-right: 8px;
}

.Title__resultName {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-left: 40px;
  margin-top: 30px;
  white-space: pre-line;
}

.Title__resultName_link {
  @extend %link-reset;
  display: inline;
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 12px;
  margin-left: 6px;
  font-weight: 900;
  color: $col-chart-blue;
  text-transform: uppercase;
  &:hover,
  &:focus {
    color: $col-blue;
  }

  &:active {
    color: $col-blue-d;
  }
}

.Title__swipeBox {
  min-height: 200px;
}

.Description {
  background-color: $col-white;
  border-radius: 12px;
  font-size: 14px;
  line-height: 18px;
}

.Description__button {
  @extend %button-reset;
  width: 100%;
  //max-width: 530px;
  height: 56px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $col-white;
  margin-top: 30px;
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  &:hover,
  &:focus {
    background-color: $col-secondary-hover;
  }

  &:active {
    background-color: darken($col-secondary-hover, 10%);
  }
}

.Description__text {
  padding: 8px 40px;
}

.Description__ArrowIcon {
  width: 32px;
  height: 32px;
  transition: transform 0.4s;
  transform: rotate(0deg);
  &._active {
    transform: rotate(-180deg);
  }
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .Title__header {
      display: block;
    }
    .Title__title {
      font-size: 24px;
      line-height: 32px;
    }
    .BackButtonBox {
      margin-bottom: 16px;
    }
    .Title__description {
    }
    .Title__resultName {
      margin-left: 0;
    }
  }
  @media (max-width: $bp-mobile-small-max-width) {
    .Title__title {
      font-size: 20px;
      line-height: 26px;
    }
    .Title__icon {
      width: 26px;
      height: 26px;
      padding: 0;
    }
  }
}
