@import '../../vars';

.NavBar {
  position: fixed;
  z-index: var(--zindex-navbar);
  top: 0;
  left: 0;
  height: 100%;
  width: var(--nav-bar-width);
  padding-top: 23px;
  padding-bottom: 28px;
  text-align: center;
  background-color: $col-blue-d;
}

.NavBar__logoLink {
  display: block;
}

.NavBar__logoImg {
  display: block;
  height: 50px;
}

.NavBar__content {
  position: relative;
  height: 100%;
}

.NavBar__menu {
  margin-top: 48px;
}
.NavBarMenu__list {
  @extend %list-simple;
}
.NavBarMenu__item {
  margin-bottom: 48px;
  &:last-child {
    margin-bottom: 0;
  }
}

.NavBarMenuLink {
  color: $col-gray-d;
  text-decoration: none;

  &:hover,
  &:focus {
    color: lighten($col-gray-d, 20%);
  }

  &:active {
    color: lighten($col-gray-d, 10%);
  }

  &._active {
    color: $col-white;

    &:hover,
    &:focus {
      color: darken($col-white, 10%);
    }

    &:active {
      color: darken($col-white, 20%);
    }
  }
}
.NavBarMenuLink__icon {
  svg {
    height: 24px;
    width: 24px;
  }
  //color: #6c7b99;
}
.NavBarMenuLink__text {
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
}

.NavBar__ext {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.ActualityDateContainer {
  width: 100%;
  min-height: 32px;
}

.NavIconButton {
  color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: $col-blue;
  display: flex;
  margin: auto;
  margin-bottom: 36px;
  &:last-child {
    margin-bottom: auto;
  }

  &:hover,
  &:focus {
    color: white;
    background-color: lighten($col-blue, 10%);
  }
  &:active {
    color: white;
    background-color: lighten($col-blue, 5%);
  }
}

.NavExtButtons {
  margin-bottom: 24px;
}

.NavBar__swipeBox {
}
.NavBar__swipeBoxInner {
  background: $col-blue-d;
}
.NavBar__swipeBoxContent {
  padding: 0 16px;
}

.NavBar__sbSearch {
  padding-bottom: 32px;
  border-bottom: 1px solid rgba($col-gray-d, 0.14);
}
.NavBar__sbSearchLink {
  @extend %flex;
  align-items: center;
  height: 56px;
  padding: 0 12px;
  border-radius: 8px;
  background: $col-white;
  color: $col-gray-d;
  text-decoration: none;
  cursor: text;

  .NavBar__searchImg {
    @extend %svg-icon;
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin-right: 14px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.5;
  }
}

.NavBar__sbExt {
  //border-top: 1px solid rgba($col-gray-d, .14);
}
.NavBar__sbExtItem {
  border-bottom: 1px solid rgba($col-gray-d, 0.14);

  &:last-child {
    border-bottom: 0;
  }
}
.NavBar__sbLogoLink {
  @extend %flex;
  align-items: center;
  padding: 24px 12px;
  color: $col-white;
  text-decoration: none;

  .NavBar__logoImg {
    width: 26px;
    min-width: 26px;
    height: 26px;
    margin-left: 0;
    margin-right: 20px;
  }
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

.NavBar__sbUser {
  @extend %flex;
  align-items: flex-start;
  padding: 24px 12px;
  color: $col-white;
  text-decoration: none;
}
.NavBar__sbUserIcon {
  @extend %flex-c;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 20px;
  border-radius: 50%;
  background: $col-blue;

  span {
    font-weight: 700;
    font-size: 10px;
    text-transform: uppercase;
  }
}
.NavBar__sbUserName {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
.NavBar__sbLogoutLink {
  margin-top: 14px;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  color: $col-chart-blue;
  text-transform: uppercase;
  text-decoration: none;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .NavBar {
      top: auto;
      bottom: 0;
      height: var(--nav-bar-height);
      width: 100%;
      padding: 0;
      background-color: $col-gray-l;
    }
    .NavBar__logo {
      display: none;
    }

    .NavBar__menu {
      height: 100%;
      margin: 0;
    }
    .NavBarMenu__list {
      display: flex;
      align-items: stretch;
      height: 100%;
    }
    .NavBarMenu__item {
      margin-bottom: 0;
      flex: 1;
    }

    .NavBarMenuLink {
      color: rgba($col-gray-d, 0.5);

      &._active {
        color: $col-gray-d;

        &:hover,
        &:focus {
          color: darken($col-gray-d, 10);
        }

        &:active {
          color: darken($col-gray-d, 20);
        }
      }
    }
    .NavBarMenuLink__icon {
      height: 24px;
      margin-top: 7px;
    }
    .NavBarMenuLink__text {
      font-size: 12px;
      font-weight: 500;
    }

    .NavBar__ext {
      position: absolute;
      bottom: 100%;

      display: none;
    }
  }

  @media (min-width: $bp-tablet-min-width) {
    @media (max-height: 700px) {
      .NavBar__menu {
        margin-top: 30px;
      }
      .NavBarMenu__item {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .NavExtButtons {
        margin-bottom: 20px;
      }
      .NavIconButton {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @media (max-height: 600px) {
      .NavBarMenu__item {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @media (max-height: 540px) {
      .NavBar__menu {
        margin-top: 16px;
      }
      .NavBarMenu__item {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .NavBarMenuLink__text {
        display: none;
      }

      .NavExtButtons {
        margin-bottom: 0;
      }
      .NavIconButton {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .ActualityDateContainer {
        display: none;
      }
    }
  }
}
