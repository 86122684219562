@import '../../vars';

.TopMenu {
  @include buttonReset;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: $col-blue-d;
  padding: 24px 0;

  &:hover {
    color: lighten($col-blue, 10%);
  }
  &:active,
  &:focus {
    color: $col-blue-d;
  }
}

.TopMenu__title {
  display: flex;
  min-width: 240px;
  flex-grow: 1;
}

.TopMenu__name {
}

.TopMenu__icon {
  display: flex;
  align-items: center;
  flex-basis: 24px;
  margin-left: 8px;
  margin-right: 8px;
  transition: transform 0.2s ease;
  transform-origin: center;
  svg {
    width: 100%;
    height: 100%;
  }
  &._active {
    transform: rotate(-180deg);
  }
}

.TopMenu__count {
  margin-left: 8px;
  color: $col-gray-d;
  font-size: 16px;
}

.TopMenu__number {
  line-height: 18px;
  min-width: 120px;
  text-align: right;
}

.TopMenu__chip {
  justify-content: flex-end;
}
