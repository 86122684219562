@import '../../../vars';

.Goals__title {
  font-size: 28px;
  line-height: 40px;
}

.Goals__name {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
}

.Goals__charts {
  margin-top: 20px;
  width: 100%;
}

.chart {
  width: 100%;
}

.chart + .chart {
  margin-top: 36px;
}

.chart__wrapper {
  position: relative;
  width: 100%;
  height: 160px;
}

.chart__legend {
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.chart__title {
  color: $col-gray-d;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
}

.chart__name {
  margin-bottom: 8px;
}

.Goals__links {
  margin-top: 40px;
}

.GoalGroup {
  width: 100%;

  &:not(:first-child) {
    border-top: 1px solid $col-gray-d;
  }
}

.GoalGroup_separator {
  border-bottom: 1px solid $col-gray-d;
}

.GoalGroup__dropdown {
  width: 100%;
}

.GoalGroup__dropdown_opened {
  padding-bottom: 24px;
}

.Title {
  font-size: 16px;
  line-height: 24px;
  color: $col-blue-d;
}

.TopMenu {
  @extend %button-reset;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  padding: 24px 0;
  text-decoration: none;
  display: flex;
  color: $col-blue-d;

  &:hover {
    color: lighten($col-blue, 10%);
  }
  &:active,
  &:focus {
    color: $col-blue-d;
  }
}

.TopMenu__icon {
  width: 9px;
  height: 9px;
  flex-basis: 16px;
  margin-right: 16px;
  transition: transform 0.2s ease;
  transform: rotate(90deg);
  svg {
    width: 100%;
    height: 100%;
  }
  &._active {
    transform: rotate(180deg);
  }
}

.TaskTitle {
  font-weight: normal;
}
