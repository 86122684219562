@import '../../../vars';
@import '../../../colors';

.ProgressView {
  position: fixed;
  top: 152px;
  right: var(--page-content-side-padd);
  width: calc(var(--right-pane-width) - var(--page-content-side-padd));
  height: 416px;
  overflow: hidden;
  z-index: var(--zindex-progresssview);
}

.progress {
  height: 344px;
  padding: 18px;
  background: $col-gray;
  color: $col-white;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.2s ease;

  &._success {
    background: $col-success;
  }

  &._warn {
    background: $col-warn;
  }

  &._error {
    background: $col-error;
  }
}

.progressView__selectMenu {
  padding: 24px 24px 0;
  height: 344px;
  border-radius: 8px;
  background-color: $col-white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectMenu__title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin-bottom: 16px;
  font-weight: 700;
  color: $col-blue-d;
}

.selectMenu__optionsList {
  width: calc(100% + 48px);
  padding: 0;
  margin: 0 -24px;
  overflow-x: auto;
  overflow-y: scroll;
  flex-grow: 1;
}

.selectMenu__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 72px;
  min-height: 72px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: $col-white;
  transition: background-color 0.2s;
  border-top: 1px solid rgba($col-gray-d, 0.36);
  &:hover {
    background-color: darken($col-white, 10%);
  }
  p {
    font-family: $fonts-default;
    font-size: 16px;
    line-height: 24px;
  }
  &._selected {
    color: $col-chart-blue;
  }
}

.selectMenu__check {
  width: 32px;
  color: $col-chart-blue;
}

.selectMenu__search {
  flex-shrink: 0;
  min-width: auto;
  height: 48px;
  margin-bottom: 24px;
  width: 100%;
  min-height: 48px;
}

.selectMenu__titleName {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.progress__RFLogo {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: $col-white;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 22px;
  }
}

.progress__title {
  width: auto;
  margin-top: 16px;
  margin-bottom: 32px;
  font-size: 24px;
  height: 32px;
  min-height: 32px;
  font-weight: 600;
  line-height: 32px;
  cursor: pointer;
  transition: color 0.2s;
  display: flex;
  flex-direction: row;

  &:hover,
  &:focus {
    color: darken($col-white, 10%);
  }
}

.progress__titleText {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress__titleIcon {
  line-height: 24px;
  margin-left: 8px;
  vertical-align: middle;
}

.progress__progress {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.progress__percent {
  font-size: 42px;
  line-height: 56px;
  font-weight: 100;
  white-space: nowrap;
}

.progress__text {
  width: 126.02px;
  font-size: 12px;
  margin-left: 14px;
  line-height: 16px;
  height: 32px;
}

.progress__chart {
  position: absolute;
  height: 129px;
  bottom: 0;
  left: 0;
  width: 100%;
}

.ProgressView__additional {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProgressView__goals {
  width: calc(100% - 56px - 16px);
  height: 56px;
  background-color: $col-white;
  position: relative;
  padding-left: 18px;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $col-black !important;

  &:active,
  &:focus {
    color: $col-black;
  }

  .ProgressView__svg {
    position: absolute;
    right: 18px;
    top: calc(50% - 12px);
  }
}

.ProgressView__download {
  width: 56px;
  height: 56px;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .ProgressView {
      position: relative;
      top: 0;
      right: 0;
      width: 100%;
      height: auto;
    }
    .progress {
      border-radius: 0;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .ProgressView__additional {
      padding: 16px var(--page-content-side-padd);
      padding-left: 0;
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
      background: $col-white;
    }
    .ProgressView__goals {
      padding-left: var(--page-content-side-padd);
      border-radius: 0;
      font-size: 18px;
    }
  }

  @media (min-width: $bp-tablet-min-width) {
    @media (max-height: 580px) {
      .progress {
        height: 270px;
      }
      .progress__RFLogo {
        position: absolute;
        top: 0;
        left: 0;
        margin: 18px;
      }
      .progress__title {
        padding-left: 32px + 20px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    @media (max-height: 500px) {
      .ProgressView {
        top: calc(var(--top-bar-height) + var(--page-content-vert-padd));
      }
    }
    @media (max-height: 450px) {
      .ProgressView {
        position: relative;
        top: 0;
        right: 0;
        height: auto;
        padding-top: var(--page-content-vert-padd);
        padding-bottom: var(--page-content-vert-padd);
      }
    }
  }
}
