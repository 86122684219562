@import './vars';

html {
  font-family: $fonts-default;
  font-size: 16px;
  box-sizing: border-box;
  background: $col-gray-l;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $col-blue-d;
}

body {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#root,
#wrapper-offset,
.page {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#wrapper-offset {
  position: relative;
  overflow: hidden;
}

.page {
  position: relative;
  height: 100%;
  padding-left: var(--nav-bar-width);
  background: $col-gray-l;
}

.fade-in-enter {
  opacity: 0;
}
.fade-in-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-in-exit {
  opacity: 1;
}
.fade-in-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.-webkit-overflow-scrolling-fix {
  overflow-y: scroll;
}
.-webkit-overflow-scrolling-fix:before {
  content: '';
  width: 0;
  float: left;
  height: calc(100% + 2px);
}

.blockPageScroll {
  // Предотвращаем косяки со скроллом на мобильных браузерах
  height: 100%;
  position: fixed;
  /* prevent overscroll bounce*/
  overflow-y: auto;
}

html.swipebox-opened {
  overscroll-behavior: none;
  body {
    overscroll-behavior: none;
  }
}

a.linkDisabled {
  pointer-events: none;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .page {
      padding-left: 0;
    }
  }
}
