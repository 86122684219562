@import '../../../vars';

.RoadmapEvent {
  border-radius: 12px;
  background-color: $col-white;
  width: 100%;
  display: grid;
  grid-template:
    'title circle'
    'bottom bottom' / 1fr min-content;
  user-select: none;
  padding: 24px 18px;
  position: relative;
  z-index: var(--zindex-roadmap-event);
}

.RoadmapEvent__left {
  grid-area: title;
}

.RoadmapEvent__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  white-space: pre-line;
}

.RoadmapEvent__circle {
  grid-area: circle;
  width: 30px;
  min-width: 30px;
  flex-basis: 30px;
  height: 30px;
  position: relative;
}

.RoadmapEvent__head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-right: 16px;
  }
}

.RoadmapEvent__statusChip {
  min-width: 180px;
}

.RoadmapEvent__dateChip {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: $col-gray-d;

  > span:not(:last-child) {
    margin-right: 8px;
  }
}

.RoadmapEvent__dateChip__date {
  min-width: 60px;
}

.RoadmapEvent__bottom {
  grid-area: bottom;
}

.RoadmapEvent__separator {
  width: 100%;
  height: 1px;
  background: $col-transparent-background;
  margin: 16px 0;
}

.RoadmapEvent__slot {
  padding-top: 16px;
  padding-bottom: 0;
}

.RoadmapEvent__documentsTitle {
  margin-bottom: 16px;
}

.RoadmapEvent__files {
  margin-bottom: 0 !important;
}

.RoadmapEvent__statusBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.RoadmapEvent__button {
  @include buttonReset;
  color: $col-blue;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .__text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    text-align: right;
  }

  .__icon {
    margin-left: 8px;
  }

  &:focus:not(:global(._pressed)) {
    outline: 1px solid $col-blue;
    outline-offset: 4px;
  }

  &:hover {
    color: lighten($col-blue, 20%);
  }

  &:active {
    color: $col-blue-d;
  }
}

.RoadmapEvent__statuses {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-right: 16px;
  }
}

.RoadmapEvent__status {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $col-gray-d;

  .__text {
  }

  .__value {
    margin-left: 4px;
    font-weight: 700;
  }
}

.RoadmapEvent__points_text {
  color: $col-gray-d;
  font-size: 8px;
  width: 30px;
  height: 30px;
  font-weight: 700;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

:export {
  greyLight: rgba($col-gray-d, 0.4);
}
