@import '../../vars';

.IndicatorPageLayout {
  display: grid;
  grid-template-columns: minmax(0, 1fr) var(--right-pane-width);
  grid-template-rows: 1fr auto;
  grid-gap: var(--page-content-side-padd);
  min-height: 100%;
  padding-top: var(--top-bar-height);
}

.IndicatorPageLayout__wrapper {
  height: 100%;
  overflow-y: auto;
}

.IndicatorPageLayout__right {
  grid-row: 1;
  grid-column: 2;
  padding-top: var(--page-content-vert-padd);
  padding-right: var(--page-content-side-padd);
}

.IndicatorPageLayout__bottom {
  grid-row: 2;
  grid-column: 1 / 3;
  width: 100%;
}

.IndicatorPageLayout__main {
  grid-row: 1;
  grid-column: 1;
  padding-top: var(--page-content-vert-padd);
  padding-left: var(--page-content-side-padd);
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .IndicatorPageLayout {
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto;
    }
    .IndicatorPageLayout__main {
      padding-right: var(--page-content-side-padd);
    }
    .IndicatorPageLayout__bottom {
      grid-row: 2;
      grid-column: 1;
    }
    .IndicatorPageLayout__right {
      grid-row: 3;
      grid-column: 1;
      padding-top: 0;
      padding-left: var(--page-content-side-padd);
      padding-bottom: var(--page-content-vert-padd);
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .IndicatorPageLayout__wrapper {
      padding-bottom: var(--nav-bar-height);
    }
  }
}
