@import '../../../vars';

.SubjectView {
  grid-area: 1 / 2 / 1 / 2;
  background: $col-white;
  overflow: hidden;
  overflow-y: auto;
  z-index: var(--zindex-subject-view);
  position: relative;
}
.SubjectView__separator {
  margin: 32px 0;
}
.SubjectView__inventory {
  margin-top: 55px;
}
