@import '../../../vars';

.EventsFilter {
  display: flex;
}
.EventsFilter__element {
  margin: 6px 8px 6px 0;
  max-width: 200px;
  font-size: 14px;
  color: $col-gray-d;
}

@media (max-width: $bp-mobile-max-width) {
  .EventsFilter {
    flex-direction: column;
    margin-bottom: 56px;
  }
  .EventsFilter__element {
    max-width: 100%;
  }
}
