@import '../../../vars';

.IndicatorsView {
  //margin-right: 28px;
  //width: 350px;
  //height: 416px;
  z-index: var(--zindex-indicatorsview);
}

.IndicatorsView__finance {
  position: static;
  height: auto;
  margin-bottom: 32px;
}

.ratings {
  height: 344px;
  padding: 24px;
  background: $col-white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.ratings__list {
  flex-grow: 1;
}

.ratings__title {
  display: flex;
  justify-content: space-between;
}

.ratings__select {
  width: 205px;
  margin-top: 9px;
  :global .select__control {
    height: 40px;
  }
}

.ratings__progressBar {
  margin-top: 16px;

  &:first-child {
    margin-top: 24px;
  }
}

.ratings__additional {
  margin-top: 26px;
  height: 40px;
  width: 100%;
}

.IndicatorsView__additional {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.IndicatorsView__goals {
  width: calc(100% - 56px - 16px);
  height: 56px;
  background-color: $col-white;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  padding-left: 24px;
  line-height: 24px;
  color: $col-black;
  text-align: left;

  &:active,
  &:focus {
    text-align: left;
  }

  .IndicatorsView__svg {
    position: absolute;
    right: 18px;
    top: calc(50% - 12px);
  }
}

.IndicatorsView__download {
  width: 56px;
  height: 56px;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .ratings {
      border-radius: 0;
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
    }

    .IndicatorsView__additional {
      padding: 16px var(--page-content-side-padd);
      padding-left: 0;
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
      background: $col-white;
    }
    .IndicatorsView__goals {
      padding-left: var(--page-content-side-padd);
      border-radius: 0;
      font-size: 18px;
    }
  }
}
