@import '../../vars';

.ChartViewTabsSlider {
  width: 100%;
  overflow: hidden;
}

.Slick {
  position: relative;
  width: 100%;
  display: flex;

  & :global {
    .slick-list {
      width: 100% !important;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }
  }
}
