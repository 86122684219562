@import '../../vars';

.InfoItemsList {
  @extend %list-simple;

  li {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.InfoItem,
.PersonItem {
  p {
    margin: 2px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.InfoItem {
  @extend %flex;
  align-items: flex-start;
  i {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-top: -3px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.InfoItem__title,
.PersonItem__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(108, 123, 153, 1);
}
.PersonItem__name {
}
.InfoItem__smallSchool {
  display: inline-block;
  padding: 4px;
  color: $col-gray-d;
  background-color: rgba($col-gray-d, 0.1);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}
