@import '../../../colors';

.tag {
  //height: 22px;
  display: inline-block;
  vertical-align: top;
  line-height: 22px;
  //white-space: nowrap;
  border-radius: 4px;
  background-color: #ef3737;
  //text-align: center;
  padding: 4px 6px;
  color: $col-gray-d;
  background-color: rgba($col-gray-d, 0.1);
  &._error {
    background-color: #ef3737;
    color: $col-white;
  }
  &._success {
    background-color: #57b356;
    color: $col-white;
  }
  &._warn {
    background-color: #ff9513;
    color: $col-white;
  }
}
