.ErrorMessage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px;
}

.ErrorMessage__text {
  font-weight: bold;
}

.ErrorMessage__description {
}

.ErrorMessage__children {
}
