@import '../../vars';

.PageOffset {
  position: relative;
  @extend %flex-c;
}

.PageLayout {
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto var(--right-pane-width);
  grid-template-rows: auto;
  width: 100%;
}

.Report {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr auto;
}

.ReportRadioGroup {
  padding: 8px 0;
  margin-bottom: 16px;
}

.ReportRadioGroup__item {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}

.ReportSidebar {
  display: flex;
  flex-direction: column;
  padding: 40px 32px 40px 40px;
  background-color: $col-white;
  min-height: 100%;
}

.ReportSidebar__title {
  margin: 0 0 24px;
  font-size: 16px;
  line-height: (24 / 16);
  font-weight: 700;
}
.ReportSwipeBox__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
}
.ReportSwipeBox__content {
  padding: 0 var(--swipebox-content-side-padd)
    var(--swipebox-content-bottom-padd);
}

.Report__Content {
  padding-right: 16px;
  grid-area: 2 / 1 / 3 / 1;
  overflow: auto;
  margin-right: calc(-1 * var(--page-content-side-padd));
}

.Report__mainText {
  margin: 0;
  font-size: 14px;
  line-height: (24 / 14);
  padding-bottom: 16px;
}

.Report__Title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: 1 / 1 / 2 / 1;
  margin-bottom: 1rem;
}

.Report__PageTitle {
  margin-bottom: 0;
}

.Report__Cards {
  grid-area: 3 / 1 / 4 / 1;

  margin-left: calc(-1 * var(--page-content-side-padd));
  margin-right: calc(-1 * var(--page-content-side-padd));

  :global .__scrollListWrapper {
    padding: 16px var(--page-content-side-padd);
  }
}

.InputSelector {
  margin-bottom: 16px;
}

.InputSelector__item {
  width: auto;
  //max-width: 300px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}

.SelectsGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.SelectsGroup__selectYear {
  //width: 100px;
  margin-right: 16px;
}

.SelectsGroup__selectMonth {
  min-width: 150px;
}

.Form {
  width: 100%;
}

.Form__submit {
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    //.Form {
    //  width: 100%;
    //}
    //.InputSelector__item {
    //  max-width: none;
    //  width: auto;
    //}
  }
}
