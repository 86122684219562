@import '../../../vars';

$ranger-width: 240px - 32px;
$mediaHeightTabletLand: 700px;

.MapView {
  position: relative;
  /*display: grid;
  grid-template-columns: minmax(0, 1fr);*/
}

.MapView__regionMap {
  z-index: var(--zindex-mapview-region-map);
}
.MapView__overlay {
  padding: 24px var(--page-content-side-padd) 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto auto 1fr auto auto;
  pointer-events: none;
  z-index: var(--zindex-mapview-overlay);
}
.MapView__PageTitle {
  pointer-events: none;
  grid-area: 1 / 1 / 2 / 1;
}
.MapView__filters {
  pointer-events: none;
  grid-area: 2 / 1 / 3 / 1;
}
.MapView__ranger {
  pointer-events: none;
  grid-area: 4 / 1 / 5 / 1;
  display: flex;
  flex-direction: row;
}
.MapView__pane {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  padding: 8px 12px;
  background: white;
  pointer-events: auto;
  border-radius: 8px;

  > *:not(:last-child) {
    margin-right: 16px;
  }
}
.MapView__rangerContainer {
  display: block;
  padding: 12px 4px 4px 4px;
  width: $ranger-width;
  min-width: 90px;
}
.MapView__cards {
  pointer-events: auto;
  grid-area: 5 / 1 / 6 / 1;
  margin-left: calc(var(--page-content-side-padd) * -1);
  margin-right: calc(var(--page-content-side-padd) * -1);
  min-height: 16px;
}

.pointer__activeEvent {
  pointer-events: auto;
}

.Subject__sbHead {
  background: $col-white;
  border-bottom: 1px solid $col-gray;
}
.Subject__sbContent {
  padding: 0;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .MapView__overlay {
      &._subject {
        padding-bottom: calc(var(--button-height) + 8px);
      }
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .MapView__overlay {
      padding-bottom: var(--nav-bar-height);

      &._subject {
        padding-bottom: calc(
          var(--nav-bar-height) + var(--button-height) + 8px
        );
      }
    }

    .MapView__pane {
      cursor: pointer;
      &._opened {
        .MapView__rangerContainer {
          width: $ranger-width;
          pointer-events: auto;
        }
      }
    }
    .MapView__rangerContainer {
      width: 42px;
      pointer-events: none;
      transition: width 0.5s;
    }
    .MapView__paneClose {
      width: 24px;
      height: 24px;
    }
  }
}

:export {
  mediaHeightTabletLand: $mediaHeightTabletLand;
}
