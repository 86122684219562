@import '../../vars';
@import '../../colors';

.PageOffset {
  position: relative;
  display: grid;
  height: 100%;
  align-items: center;
  justify-items: center;
  overflow: auto;
  grid-template-columns: 1fr minmax(300px, var(--login-page-max-width)) 1fr;
}

.PageCard {
  padding: var(--login-page-padding);
  max-height: 544px;
  width: 100%;
  background-color: $col-white;
  grid-column: 2;
  border-radius: 12px;
}

.PageLogo {
  margin-bottom: 32px;
  text-align: center;
  svg {
    width: 67px;
    height: 67px;
  }
}

.PageTitle {
  margin: 0 0 32px;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
}

.Form {
  max-width: 330px;
}

.Form__input {
  margin: 0 auto 8px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 16px;
  }
}

.Form__submit {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 56px;
}

.Form__error {
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
  color: $col-error;
  text-align: center;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .PageCard {
      background: none;
    }
  }
}
