@import '../../../colors';
@import '../../../vars';

$col-secondary-hover: rgb(233, 234, 237);

.TextInput {
  width: 330px;
  height: 72px;
  position: relative;
  font-family: inherit;
}
._disabled {
  opacity: 0.5;
}

.TextInput__input {
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 40px;
  line-height: 18px;
  background-color: $col-light-background;
  outline: none;
  font-family: inherit;
  &:hover,
  &:focus {
    background-color: $col-secondary-hover;
  }
  &:disabled {
    &:hover,
    &:focus {
      background-color: $col-light-background;
    }
  }
  &::placeholder {
    position: absolute;
    opacity: 0.5;
    color: $col-gray-d;
    font-size: 14px;
    line-height: 16px;
  }
}

.TextInput__icon {
  position: absolute;
  top: calc(50% - 12px - 8px);
  right: 20px;
}

._success {
  path {
    fill: $col-blue;
  }
}

._error {
  &:hover,
  &:focus {
    background-color: rgba(253, 235, 235, 1);
  }
  &:disabled {
    &:hover,
    &:focus {
      background-color: rgba(253, 235, 235, 1);
    }
  }
  background-color: rgba(253, 235, 235, 1);
  path {
    fill: $col-error;
  }
}

.TextInput__error {
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  color: $col-error;
}
