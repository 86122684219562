@import '../../../vars';

$twoColumns: 530px;
$gap: 16px;
$oneColumn: ($twoColumns - $gap)/2;

.RoadMap {
  //margin: 0 24px;
}

.RoadMap__wrapper {
  margin: 56px auto;
  //width: $twoColumns;
}

.RoadMap__buttons {
  display: flex;
}

.RoadMap__buttonsItem {
  width: auto;
  font-size: 16px;
  line-height: 24px;
  color: $col-gray-d;
  outline: none;
  background: none;
  border: none;
  text-decoration: none;
  text-align: left;
  font-weight: 700;
  cursor: pointer;

  &._active {
    color: $col-blue-d;
  }
  & + & {
    margin-left: 24px;
  }
}

.RoadMap__filter {
  overflow: hidden;
  overflow-x: auto;
  margin-top: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}
.RoadMap__filterWrapper {
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
}
.RoadMap__filterItems {
  display: flex;
}

.CardView__filterItem {
  margin-right: 14px;
}

.CardsMap {
  margin-top: 16px;
}

.CardsMap__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($oneColumn, 1fr));
  grid-auto-flow: row;
  grid-gap: $gap;

  &._results {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.CardsMap__link {
  display: block;
  text-decoration: inherit;
  color: inherit;
  width: 100%;
  height: 100%;

  &:hover {
    color: $col-blue;
  }

  &:active,
  &:focus {
    color: lighten($col-blue, 10%);
  }

  &._results {
    width: 100%;
  }
}

.CardsMap__cardsItem {
  width: 100%;
  height: 100%;
}

.CardsMap__buttonMore {
  margin: 48px auto 0;
}

.RoadMap__buttons {
}

.RoadMap__ProgressIndicator {
  margin: 60px auto 0;
}

.CardsMap__noData {
  color: $col-gray-d;
  margin: 30px auto;
}

:export {
  success: $col-success;
  error: $col-error;
  warn: $col-warn;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .RoadMap__filter {
      padding: 0 var(--page-content-side-padd);
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
    }

    .CardsMap__grid {
      grid-template-columns: 100%;
      &._results {
        grid-template-columns: 100%;
      }
    }
    .CardsMap__link {
      width: auto;
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
    }
  }
}
