@import '../../../vars';

.MapFiltersPopup {
  position: fixed;
  z-index: var(--zindex-map-filters-popup);
  top: 0;
  left: var(--nav-bar-width);
  right: 0;
  bottom: 0;
  background: $col-white;
  overflow-y: auto;
}

.MapFiltersPopup__content {
  margin: 24px auto 152px;
  max-width: 395px;
}
.MapFiltersPopup__PageTitle {
  position: relative;
  width: calc(100% + 40px);
  left: -40px;
}
.MapFiltersPopup__BackBtn {
}

.FiltersLink__subTitle {
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}
.FiltersLink__search {
  margin-top: 22px;
}
.FiltersLink__links {
  margin-top: 48px;
}
.FiltersLink__link {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FiltersLink__arrow {
  color: $col-gray-d;
  min-width: 24px;
  transition: color 0.2s;
  transform: rotate(-90deg);
}

.MapFiltersPopup__btns {
  position: fixed;
  bottom: 24px;
  margin: 0 auto;
  left: calc(50% - 140px + 36px);
}

.MapFiltersPopup__apply {
  width: 280px;
}

.MapFiltersPopup__clear {
  width: 280px;
  margin-top: 8px;
}

.FederalProjects__checkboxes {
  margin-top: 46px;
}

.FederalProjects__checkbox {
  border-top: 1px solid rgba($col-gray-d, 0.1);
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .MapFiltersPopup {
      left: 0;
      padding: 0 var(--page-content-side-padd);
    }
    .MapFiltersPopup__PageTitle {
      width: 100%;
      left: 0;
    }

    .MapFiltersPopup__btns {
      left: 0;
      width: 100%;

      button {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
