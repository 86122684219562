@import '../../../vars';

.Actions {
  margin-top: 48px;
}
.Action {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }

  a,
  button {
    width: 100%;
  }
}
