@import '../../../vars';

.TopBar__filters {
  @extend %flex;
  width: 100%;
  align-items: center;
}

.TopBar__year {
  margin-right: 64px;
}

.TopBar__HorizontalScroll {
}

.TopBar__projectsWrapper {
  min-width: 0;
  flex-grow: 1;
}

.TopBar__projects {
  @extend %flex;
  align-items: center;
}

.TopBar__project {
  @extend %flex;
  align-items: center;
  max-width: 160px;
}

.TopBar__projectSelector {
  font-size: 12px;
}

.horizontalScroll {
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .TopBar__year {
      margin-right: 32px;
    }
  }
}
