@import '../../vars';

.ActualityDate {
  color: $col-gray-d;
  white-space: pre;

  p {
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    margin: 0;
  }
}

.ActualityDate_nav {
  .ActualityDate__date {
    color: $col-white;
  }
}

.ActualityDate_gray {
}

.ActualityDate_description {
  display: flex;
  color: rgba($col-gray-d, 0.6);
  p {
    font-weight: 500;
  }
}
