@import '../../vars';

.GridOffset {
  position: relative;
  @extend %flex-c;
  height: 100%;
}
.GridLayout {
  @include gridLayout12;
  grid-auto-columns: calc(
    var(--page-content-side-padd) - var(--grid-col-gutter)
  );
  padding-top: var(--top-bar-height);
}

@media only screen {
  @media (max-width: $bp-tablet-land-max-width) {
    .GridLayout {
      grid-template-columns: repeat(var(--grid-col-amount), 1fr);
      width: 100%;
    }
  }
  @media (max-width: $bp-tablet-max-width) {
    .GridLayout {
      grid-template-columns: 100%;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .GridOffset {
      //height: auto;
    }
    .GridLayout {
      height: auto;
      max-height: none;
      min-height: 100%;
    }
  }

  @media (min-width: $bp-xlg-desktop-min-width) {
    .GridOffset {
      padding: 0 var(--page-content-side-padd);
    }
    .GridLayout {
      grid-template-columns: repeat(var(--grid-col-amount), 1fr);
      width: 100%;
      max-width: 1600px;
    }
  }
}
