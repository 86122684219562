@import '../../vars';

.InfoView {
}

.SubsidiaryView {
  background: $col-white;
}

.DateCard {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.PercentCard {
  font-size: 42px;
  line-height: 56px;
  font-weight: 100;
  margin: 0;
}

.InfoView__eventList {
  margin-top: 38px;
  > p {
    font-weight: 700;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 8px;
  }
}
