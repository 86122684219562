@import '../../colors';
@import '../../vars';

.RegionsIndicator {
  margin-top: 70px;
}

.RegionsIndicator__layout {
  display: flex;
  height: calc(100vh - var(--top-bar-height));
}

.indicators {
  overflow-y: auto;
  padding: 24px 16px 0;
}

.indicators__title {
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 40px;
  display: flex;
}

.indicators__title_btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 14px;
  margin-top: 7px;
  padding: 0;
}
.indicators__filters {
  display: flex;
  max-width: 600px;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 16px;
  }
}
.indicators__quarter {
}
.indicators__search {
  width: 100%;
}
.indicators__items {
  margin-top: 32px;
}

.region {
  display: flex;
  min-height: 40px;
  margin-top: 12px;
  align-items: center;
  width: 100%;
  max-width: 600px;
  cursor: pointer;
  outline: none;
  border-radius: 6px;
  background: none;
  border: none;
  text-align: left;
  font-family: inherit;

  &:hover,
  &:focus {
    background-color: $col-light-background;
  }
  &:active {
    background-color: darken($col-light-background, 10%);
  }
  &._active {
    background-color: $col-white;
    &:hover {
      background-color: $col-white;
    }
  }
}
.region__title {
  width: 200px;
  min-width: 200px;
  padding: 10px;
}
.region__indicator {
  width: calc(100% - 200px);
  padding-right: 10px;
}
.region__indicator_line {
}
.MapView {
  background-color: $col-white;
  height: calc(100vh - var(--top-bar-height));
  overflow: auto;
  padding: 28px 45px 0;
  position: relative;
}
.MapView__close {
  position: absolute;
  top: 28px;
  right: 32px;
  z-index: var(--zindex-mapview-close);
}
.MapView__flag {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 6px;
  background-color: $col-gray-l;
  padding: 4px;
  svg,
  img {
    width: 24px;
    height: 24px;
  }
}
.MapView__map {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: var(--zindex-mapview-map);
  bottom: 0;
  left: 0;
}
.MapView__content {
  position: relative;
  z-index: var(--zindex-mapview-content);
}
.MapView__title {
  font-weight: 700;
  margin-top: 8px;
  font-size: 24px;
  line-height: 32px;
}
.finance {
  margin-top: 28px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min-content, 1fr));
  grid-auto-flow: row;
  grid-gap: 24px;
}
.finance__item {
  display: flex;
  min-width: 0;
}
.finance__number {
  font-weight: 100;
  font-size: 42px;
  line-height: 50px;
  white-space: nowrap;
}
.finance__description {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-left: 2px;
}
.finance__status {
  display: flex;
  align-items: center;
  width: 4px;
  height: 50px;
}
.finance__status_svg {
  width: 6px;
  min-width: 6px;
  height: 6px;
}
.finance__data {
  margin-left: 8px;
}

.charts {
  margin-top: 43px;
}
.charts__item {
  padding: 20px 0;
  min-height: 112px;
  border-top: 1px dashed rgba($col-gray-d, 0.2);
}
.charts__title {
  color: $col-gray-d;
  font-size: 12px;
  line-height: 16px;
}
.charts__button {
  margin: 32px auto;
}

.RightContentButton {
  @extend %flex;
  align-items: center;
  height: 56px;
  padding: 0 12px;
  background: $col-success;
  border-radius: 10px;
  color: $col-white;
  cursor: pointer;
}
.RightContentButton__icon {
  @extend %flex-c;
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 6px;
  background: $col-white;

  svg,
  img {
    display: block;
    width: 24px;
    height: 24px;
  }
}
.RightContentButton__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .indicators {
      padding-bottom: 56px + 20px;
    }
    .indicators__search {
      > div {
        min-width: 0;
      }
    }

    .MapView {
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 0 0 var(--swipebox-content-bottom-padd);
      overflow: visible;
    }
    .MapView__map {
      position: relative;
      order: 2;
      height: auto;
      padding-top: 107%;
      margin-top: 32px;

      > div {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .MapView__content {
      order: 1;
      padding: 28px 45px 0;
    }
    .charts {
      order: 3;
      padding: 0 45px;
    }
    .charts__button {
      order: 4;
      margin-bottom: 0;
    }

    .region {
      max-width: none;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .indicators {
      padding-bottom: calc(var(--nav-bar-height) + 56px + 32px);
    }
    .indicators__title {
      font-size: 24px;
      line-height: 32px;
    }

    .indicators__filters {
      flex-wrap: wrap;
      margin-top: -16px;

      > * {
        margin-top: 16px;
      }
    }
    .indicators__search {
      min-width: 100%;
    }
  }
  @media (max-width: $bp-mobile-small-max-width) {
    .indicators__title {
      font-size: 20px;
      line-height: 26px;
    }
  }

  @media (min-width: $bp-lg-desktop-min-width) {
    .region {
      max-width: none;
    }
  }
}
