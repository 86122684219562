@import '../../../colors';

.Chip {
  display: flex;
  height: 24px;
  padding-right: 8px;
  align-items: center;
  font-size: 10px;
  color: $col-gray-d;
  border-radius: 8px;
  border: none;
  overflow: hidden;
  background: none;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  font-weight: 700;
  font-family: inherit;

  &:hover,
  &:focus {
    background-color: $col-transparent-background;
  }
  &:active {
    background-color: darken($col-transparent-background, 10%);
  }
  &._active {
    background-color: $col-transparent-background;
    color: $col-black;
    .Chip__icon {
      color: $col-black;
    }
  }
}

.Chip__icon {
  display: flex;
  align-items: center;
  max-width: 18px;
  max-height: 18px;
  color: $col-gray-d;
  //padding-left: 6px;
}
.Chip__title {
  margin-left: 6px;
  letter-spacing: 0.4px;
}

.ChipText {
  display: flex;
  height: 24px;
  padding-right: 8px;
  align-items: center;
  font-size: 10px;
  color: $col-gray-d;
  text-transform: uppercase;
  outline: none;
  font-family: inherit;
  font-weight: 700;

  &._success {
    color: $col-success;
  }
  &._error {
    color: $col-error;
  }
  &._warn {
    color: $col-warn;
  }
}

.ChipText__icon {
  display: flex;
  align-items: center;
  max-width: 18px;
  max-height: 18px;
  //padding-left: 6px;
}
.ChipText__title {
  margin-left: 6px;
  letter-spacing: 0.4px;
}

.ChipRadioGroup {
  display: flex;
  flex-direction: row;

  > * {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

:export {
  defaultColor: $col-black;
}
