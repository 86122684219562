@import '../../../colors';
@import '../../../vars';

.week {
  @extend %flex-btw;
}

.day {
  min-width: 24px;
  min-height: 24px;
  font-size: 14px;
  border-radius: 7px;
  transition: color 0.2s, background-color 0.2s;
  @extend %flex-c;
  @extend %button-reset;
}

.dayHeadEntry {
  padding: 0;
  opacity: 0.5;
  font-weight: 700;
  color: $col-gray-d;
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
}
.dayHead {
  min-width: 24px;
  @extend %flex-c;
}

.dayWeekend {
  color: $col-gray-d;
}

.dayDifferent {
  color: $col-gray-d;
}

.calendarEntry {
  padding: 15px 0;
  cursor: pointer;
  position: relative;
  &:hover,
  &:active {
    .day {
      color: $col-white;
      background-color: lighten($col-blue, 20%);
    }
  }
}
.calendarEntrySelected {
  &:hover,
  &:active {
    .day {
      color: $col-white;
      background-color: $col-blue;
    }
  }
  .day {
    color: $col-white;
    background-color: $col-blue;
  }
}

.dayToday {
  border: 1px solid $col-gray-d;
}

.indicators {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 7px;
  left: 0;
}

.indicators__item {
  width: 8px;
  height: 8px;
}
