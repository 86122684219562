@import '../../../vars';

.Results__filters {
  overflow: hidden;
  overflow-x: auto;
  margin-bottom: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
}
.Results__filtersWrapper {
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
}
.Results__filtersItems {
  @extend %flex;
  align-items: center;
}
.Results__filter {
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
}
.Results__list {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--result-min-width), 1fr)
  );
  grid-auto-flow: row;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
}
.Results__empty {
  text-align: center;
}

.Result {
  min-width: 0;
  height: 100%;
  padding: 16px 14px;
  border-radius: 8px;
  background: $col-white;
  cursor: pointer;
  @extend %link-reset;

  &:hover {
    color: $col-gray-d;
  }

  p {
    margin: 0;
    overflow-wrap: break-word;
  }

  &._red {
    .Result__label {
      color: $col-error;
    }
  }
  &._orange {
    .Result__label {
      color: $col-warn;
    }
  }
  &._green {
    .Result__label {
      color: $col-success;
    }
  }
}
.Result__wrapper {
  height: 100%;
}
.Result__label {
  @extend %flex;
  align-items: center;
  i {
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-top: -2px;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }
}
.Result__text {
  margin-top: 16px;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}

.Results__slider {
  overflow: hidden;
  margin-left: -16px;
  margin-right: -16px;
  .Result {
    max-width: var(--result-min-width);
    width: calc(100vw - 30px * 2);
    height: 152px;
    user-select: none;
  }
  .Result__wrapper {
    overflow: hidden;
  }
}
.Slick {
  width: 100%;

  & :global {
    .slick-list {
      width: 100%;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }
    .slick-slide {
      padding: 0 8px;
    }
  }
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .Results__filters {
      padding: 0 var(--page-content-side-padd);
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
    }
  }
}
