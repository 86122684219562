@import '../../../vars';

.MainCard {
  display: grid;
  //grid-template-columns: 1fr 1fr;
  //grid-auto-flow: dense;
  //grid-gap: 32px;

  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--main-card-min-width), 1fr)
  );
  grid-auto-flow: row;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
}

.MainCard__card {
  border-radius: 8px;
  height: 122px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: $col-white;

  &._success {
    color: $col-white;
    background-color: $col-success;
  }
  &._error {
    color: $col-white;
    background-color: $col-error;
  }
  &._warn {
    color: $col-white;
    background-color: $col-warn;
  }
}

.MainCard__title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  flex-grow: 1;
}

.MainCard__actualityDate {
  p {
    color: rgba($col-white, 0.6);
    font-weight: 500;
    font-size: 11px;
  }
}

.MainCard__percent {
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .MainCard {
      grid-row-gap: 16px;
    }
  }
}
