@import '../../../vars';

.FileCard {
  position: relative;
  padding: 24px 24px 32px 56px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: $col-transparent-background;
}

.FileCard__icon {
  position: absolute;
  top: 28px;
  left: 0;
  margin-left: -6px;
}

.FileCard__corner {
  position: absolute;
  top: 0;
  right: 26px;
}

.FileCard__cornerIcon {
  position: absolute;
  top: 0;
  left: 0;
  &._blue {
    color: $col-blue;
  }
  &._transparent {
    color: $col-gray-l;
    transform: scale(-1, -1);
  }
}

.FileCard__params {
  margin: 0 0 4px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.FileCard__additionalInfo {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
