@import '../../../colors';

.Tab {
  width: 285px;
  height: 100%;
  border: none;
  background: none;
  text-align: left;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-family: inherit;

  &:hover {
    .Tab__article {
      color: lighten($col-gray-d, 10%);
    }
    ._active {
      color: $col-black;
    }
  }

  &._secondary {
    .Tab__article {
      color: rgba($col-white, 0.5);
    }
    ._active {
      color: $col-white;
    }
  }
}

.Tab__article {
  transition: color 0.2s;
  color: $col-gray-d;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  //margin-top: 16px;
}

._active {
  color: $col-black;
}
