@import '../../../colors';
@import '../../../vars';

$col-secondary-hover: rgb(233, 234, 237);
$tooltipWidthLarge: 56px;
$tooltipWidth: 48px;
$tooltipWidthSmall: 40px;

.DownloadButton {
  position: relative;
}

.DownloadButton__button {
  @extend %button-reset;
  height: $tooltipWidth;
  width: $tooltipWidth;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $col-chart-blue;
  cursor: pointer;
  padding: 0;

  &:hover {
    background-color: $col-blue;
  }
  &:focus,
  &:active {
    background-color: lighten($col-blue, 10%);
  }
}

.Button_large {
  height: $tooltipWidthLarge;
  width: $tooltipWidthLarge;
}
.Button_small {
  height: $tooltipWidthSmall;
  width: $tooltipWidthSmall;
}

._active {
  .DownloadButton__download {
    display: none;
  }
  .DownloadButton__close {
    display: block;
  }
  .DownloadButton__tooltip {
    opacity: 1;
    pointer-events: auto;
  }
}

.DownloadButton__download {
  height: 24px;
  width: 24px;
  color: $col-white;
}

.DownloadButton__close {
  height: 24px;
  width: 24px;
  margin: 0;
  display: none;
  color: $col-white;
}

.DownloadButton__tooltip {
  opacity: 0;
  pointer-events: none;
  background-color: $col-secondary-hover;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  top: $tooltipWidth + 8px;
  z-index: var(--zindex-downloadbtn-tt);
}

.tooltip_large {
  top: $tooltipWidthLarge + 8px;
}
.tooltip_small {
  top: $tooltipWidthSmall + 8px;
}

.DownloadButton__link {
  min-height: 57px;
  height: 100%;
  background-color: $col-secondary-hover !important;
  display: flex;
  padding: 8px 16px !important;
  justify-content: space-between !important;
  align-items: center;
  text-decoration: none;
  outline: none;
  border-radius: 0;

  &:hover {
    background-color: darken($col-secondary-hover, 10%) !important;
  }
  & + & {
    border-top: 1px rgba($col-gray-d, 0.3) solid;
  }
}

.DownloadButton__fileName {
  color: rgba($col-blue-d, 1);
  font-size: 14px;
  line-height: 18px;
  margin-right: 8px;
  &:hover,
  &:focus,
  &:active {
    color: rgba($col-blue-d, 1);
  }
}

.DownloadButton__icon {
  flex-basis: 24px;
  min-width: 24px;
  color: $col-chart-blue;
}
