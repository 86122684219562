@import '../../../vars';
.RangeContainer {
  margin: 1px 8px;
}
.Range {
  width: 100%;
  :global {
    .rc-slider-rail {
      padding: 0;
      border-radius: 0;
      height: 3px;
      background-color: $col-transparent-background;
    }
    .rc-slider-track {
      height: 3px;
      background: none;
    }
    .rc-slider-handle {
      z-index: 1;
      height: 15px;
      width: 15px;
      margin-top: -6px;
      border: 4px solid transparent;
      background-color: white;
      box-sizing: border-box;
      transition: border-color 0.2s;
      &:focus,
      &:hover,
      &:active {
        box-shadow: none;
        outline: none;
      }
    }
    .rc-slider-dot {
      display: none;
    }
    .rc-slider-mark {
      top: -100%;
      font-size: 8px;
      line-height: 12px;
      font-weight: 700;
    }
    .rc-slider-mark-text {
      white-space: nowrap;
    }
    .rc-slider-mark-text,
    .rc-slider-mark-text-active {
      color: $col-blue-d;
    }
  }

  &._mobile {
    &._collapsed {
      :global {
        .rc-slider-track {
          left: 0 !important;
          right: 0 !important;
          width: 100% !important;
          transition: left, right, width;
          transition-duration: 0.2s;
        }
        .rc-slider-handle {
          transition: left;
          transition-duration: 0.2s;
          &.rc-slider-handle-1 {
            left: 0 !important;
          }
          &.rc-slider-handle-2 {
            left: 100% !important;
          }
        }
        .rc-slider-mark-text {
          transition: left;
          transition-duration: 0.2s;
          &:first-child {
            left: 0 !important;
          }
          &:last-child {
            left: 100% !important;
          }
        }
      }
    }
  }

  &._type-completion {
    .Range__axis {
      background: linear-gradient(
        90deg,
        $col-error 0%,
        $col-error 50%,
        $col-warn 50%,
        $col-warn 99%,
        $col-success 100%,
        $col-success 100%
      );
    }
  }
}
.Range__axis {
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    $col-error 0%,
    $col-error 50%,
    $col-warn 50%,
    $col-warn 80%,
    $col-success 80%,
    $col-success 100%
  );
}
.Range__leftOffset,
.Range__rightOffset {
  position: absolute;
  top: 0;
  height: 100%;
  background: $col-white;
  opacity: 0.8;
}
.Range__leftOffset {
  left: 0;
}
.Range__rightOffset {
  right: 0;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .RangeContainer {
      margin-bottom: 0;
    }
    .Range {
      width: 100%;
    }
  }
}
