@import '../../vars';

.SearchLayout {
  width: 100%;
  max-width: calc(584px + var(--page-content-side-padd) * 2);
  margin: 0 auto;
  padding: 56px var(--page-content-side-padd) 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SearchFilter {
  display: flex;
  align-items: center;
  margin-top: 16px;
  flex-wrap: wrap;
}

.SearchFilter__item {
  margin-right: 18px;
}

.Spinner {
  padding-top: 56px;
}

.SearchResult {
}

.SearchResult__showMore {
  display: block;
  margin: 16px auto;
}

.SearchResult__startSearching {
  display: block;
  margin: 24px auto 16px;
}

.SearchResult__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $col-gray-d;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba($col-gray-d, 0.3);
}

.SearchResult__title {
  text-transform: uppercase;
}

.SearchResult__count {
  text-transform: uppercase;
}

.SearchResult__item {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 0;
  color: $col-blue-d;
  text-decoration: none;

  & + & {
    border-top: 1px solid rgba($col-gray-d, 0.3);
  }

  &:hover {
    color: $col-blue;
  }
}

.SearchInfo {
  text-align: center;
  color: $col-gray-d;
  margin-top: 56px;
  &._noData {
    margin-top: 36px;
  }
}

.SearchResults {
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: 56px;
  margin-top: 24px;
  padding-top: 22px;
}

.SearchResults__item {
  &:not(:first-child) {
    margin-top: 16px;
  }
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .SearchLayout {
      padding-top: 40px;
    }
    .SearchBack {
      margin-bottom: 20px;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .SearchLayout {
      padding-bottom: var(--nav-bar-height);
    }
  }
}
