@import '../../colors';

.PreviewLayout {
  background-color: $col-white;
}

.content {
  max-width: 540px;
  margin: 0 auto;
  padding: 32px 16px;
  h1 {
    margin-top: 0;
  }
}
