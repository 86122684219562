@import '../../vars';

.TaskStatus {
  margin-top: 16px;
  margin-bottom: 16px;
}

.Matrix {
  margin: 56px auto 0;
  width: 280px;
}

.ControlPoints {
  margin-top: 56px;
}

.SubTitle {
  font-size: 1.5em;
}

.Popup__descriptionTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 18px;
  margin-top: 20px;
  color: $col-blue-d;
}

.RoadmapPage__eventListContainer {
  margin-top: 40px;
}
.RoadmapPage__eventListPlaceholder {
  text-align: center;
  color: $col-gray-d;
}

.RoadmapPage__heading {
  margin-bottom: 24px;
}
