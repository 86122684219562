.Indicators {
  position: relative;
  margin-top: 55px;
}

.Indicators__title {
  margin-bottom: 8px;
}
.Indicators__select {
  width: 100%;
}
