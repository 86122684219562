@import '../../vars';
@import '../../colors';

.PageOffset {
  position: relative;
  @extend %flex-c;
}

.PageLayout {
  padding-top: var(--top-bar-height);
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto var(--right-pane-width);
  grid-template-rows: auto;
  width: 100%;
  position: relative;
}

.CalendarPage__EventList {
}

.CalendarPage__Calendar {
  min-height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background: $col-white;
}

.Calendar {
  width: 304px;
  margin: 0 auto;
}

.Calendar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.Calendar__month {
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
}

.Calendar__monthSpinner {
  width: 1em;
  height: 1em;
}

.Calendar__controls {
  display: flex;
}

.CalendarPage__report {
  width: 279px;
  margin: 68px auto 0;
}

.CalendarPageSidebar {
}

.CalendarPageSidebar__title {
  margin: 0 0 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.CalendarPage__capitalized {
  text-transform: capitalize;
}
.CalendarPageSidebar__fullName {
  margin: 0;
  font-size: 14px;
  line-height: 24px;
}

.CalendarPageSidebar__additional {
  font-size: 14px;
  line-height: 24px;
  margin: 24px 0;
}

.CalendarPageSidebar__additional {
  > *:not(:last-child) {
    margin-right: 6px;
  }
}

.CalendarPageSidebar__button {
  @extend %link-reset;
  display: inline-block;
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 12px;
  font-weight: 900;
  color: #3d68e3;
  text-transform: uppercase;
}

.CalendarPageSidebar__document {
  @extend %button-reset;
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: #3d68e3;
  margin-top: 4px;
}

.CalendarPageSidebar__executor {
  display: flex;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 32px;
}
.CalendarPageSidebar__executorAvatar {
  height: 32px;
  width: 32px;
  margin-right: 8px;
  border-radius: 8px;
  background-color: $col-gray-l;
  line-height: 32px;
  text-align: center;
}
.CalendarPageSidebar__executorTitle {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: $col-gray-d;
}
.CalendarPageSidebar__executorName {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

.CalendarPageSidebar__descriptionSection {
  padding-top: 24px;
  border-top: 1px solid $col-transparent-background;
}
.CalendarPageSidebar__descriptionText {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .CalendarPage__Calendar {
      background: none;
      padding-top: 0;
      padding-bottom: var(--swipebox-content-bottom-padd);
    }
    .CalendarPage__report {
      margin-top: 20px;
    }
  }
}
