@import '../../../colors';
@import '../../../vars';

.BackButton {
  display: inline-block;
  vertical-align: top;
  line-height: 24px;
  color: $col-gray-d;
  cursor: pointer;
  transition: color 0.2s;

  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  &:hover {
    color: $col-gray;
  }
}
.BackButton__content {
  @extend %flex;
  align-items: center;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .BackButton {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
