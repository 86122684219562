@import '../../../vars';

.DayEventList {
  margin: 0 auto;
}

.DayEventList__noData {
  color: $col-gray-d;
  margin-top: 24px;
}

.DayEventList__date {
  font-size: 28px;
  line-height: 40px;
}

.DayEventList__capitalize {
  text-transform: capitalize;
}

.DayEventList__content {
  margin-top: 14px;
  padding: 12px 22px 16px 18px;
  border-radius: 11px;
  background-color: $col-white;
  & + & {
    margin-top: 16px;
  }
  cursor: pointer;
}

.DayEventList__status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DayEventList__name {
  font-size: 12px;
  color: $col-gray-d;
  margin-top: 8px;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

.dayEventList__date {
}

.DayEventList__type {
  font-size: 10px;
  color: $col-gray-d;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding-right: 8px;
  margin-top: 6px;
}

.DayEventList__separator {
  padding-right: 8px;
  font-size: 10px;
  color: $col-gray-d;
  font-weight: 700;
}

.DayEventList__text {
  margin-top: 6px;
}

.chipText {
  display: flex;
  align-items: center;
  color: $col-gray-d;

  &._success {
    svg {
      color: $col-success;
    }
  }
  &._error {
    svg {
      color: $col-error;
    }
  }
  &._warn {
    svg {
      color: $col-warn;
    }
  }
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .DayEventList__date {
      font-size: 24px;
      line-height: 32px;
    }
    .DayEventList__content {
      border-radius: 0;
      padding-left: var(--page-content-side-padd);
      padding-right: var(--page-content-side-padd);
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
    }
  }
  @media (max-width: $bp-mobile-small-max-width) {
    .DayEventList__date {
      font-size: 20px;
      line-height: 26px;
    }
  }
}
