@import '../../../vars';

.ReportChartView {
  max-width: 530px;
  margin-bottom: 56px;
}

.ReportChartView__title {
  font-weight: 700;
  color: $col-blue-d;
  font-size: 16px;
  line-height: 24px;
}

.ReportChartView__date {
  font-weight: 500;
  color: $col-gray-d;
  font-size: 14px;
  line-height: 20px;

  > span:not(:first-child) {
    margin-left: 16px;
  }
}

.ReportChartView__children {
  margin-top: 16px;
  width: 100%;
  height: 280px;

  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
.ReportChartView__inner {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .ReportChartView__content {
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
    }
    .ReportChartView__children {
      width: auto;
      height: auto;
      padding-left: var(--page-content-side-padd);
      padding-right: var(--page-content-side-padd);
    }
  }

  @media (min-width: $bp-lg-desktop-min-width) {
    .ReportChartView {
      max-width: 800px;
    }
  }
}
