@import '../../vars';
@import '../../colors';

.SwipeBox__offset {
  position: fixed;
  z-index: var(--zindex-swipebox-offset);
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba($col-black, 0.4);
  opacity: 0;
  transition: opacity 0.2s;

  &._opened {
    z-index: var(--zindex-swipebox-offset-opened);
    opacity: 1;
  }
}
.SwipeBox {
  position: fixed;
  z-index: var(--zindex-swipebox);
  top: 100%;
  left: 0;
  width: 100%;
  pointer-events: none;

  &._fullscreen {
    //height: 100%;
  }
}
.SwipeBox__inner {
  position: relative;
  height: 100%;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 10px 10px 0 0;
  background: $col-gray-l;
  overflow: hidden;
  pointer-events: auto;
}
.SwipeBox__head {
  position: relative;
  height: var(--swiper-height);
  padding-top: 8px;
  cursor: pointer;
  //border-bottom: 1px solid $col-gray;
}
.SwipeBox__swiper {
  width: 37px;
  height: 5px;
  border-radius: 3px;
  margin: 0 auto;
  background-color: #d4d4d4;
}
.SwipeBox__scroll {
  max-height: calc(100% - var(--swiper-height));
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
.SwipeBox__content {
  position: relative;
  //padding: 0 var(--swipebox-content-side-padd) 34px;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .SwipeBox {
      padding-left: var(--nav-bar-width);
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .SwipeBox {
      padding-left: 0;
    }
  }
}

.Popup {
  --popup-padding-side: 48px;
  --popup-padding-bottom: 24px;

  position: absolute;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 8px;
  padding: 32px var(--popup-padding-side) var(--popup-padding-bottom)
    var(--popup-padding-side);
  outline: none;
  border: none;
}

.PopupOverlay {
  z-index: var(--zindex-map-filters-popup);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
