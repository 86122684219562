@import '../../../vars';

.ResultsInfoView {
  background: $col-white;
  width: 100%;
  padding-bottom: 56px;

  @media (min-width: $bp-tablet-land-min-width) {
    min-height: 100%;
  }
}

.ResultsInfoView__bottomLoader {
  margin-top: 24px;
}

.ChartData {
  background: $col-blue;
  padding: 32px 24px;
}
.ChartData__type {
  width: 100%;
}
.ChartData__select {
  height: 40px;
  width: 100%;
}
.ChartData__filters {
  display: flex;
  flex-direction: row;
  margin-top: 14px;

  > *:not(:last-child) {
    margin-right: 16px;
  }
}
.ChartData__title {
  color: white;

  > button {
    &:hover,
    &:focus {
      color: $col-gray;
    }
  }
}
.ChartData__charts {
  margin: 32px -24px;
  min-height: #{232px + 32px};
}
.ChartData__scroll {
  padding: 0 24px;
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
.ChartData__inner {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
}
.ChartData__chartItem {
  position: relative;
}
.ChartData__chart {
  height: 208px;
}
.ChartData__chartTooltip {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: $col-white;
  white-space: pre;
}
.TotalData {
  margin-top: 45px;
  padding: 0 24px;
}
.TotalData__title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.TotalData__select {
  width: 100%;
  margin-top: 18px;
  :global .select__control {
    height: 40px;
  }
}
.TotalData__legends {
  margin-top: 44px;
  display: flex;
}
.TotalData__legends__item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.TotalData__legends__dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
}
.TotalData__legends__title {
  font-size: 12px;
  line-height: 16px;
}

.TotalData__charts {
  margin-top: 20px;
}
.TotalData__chart {
  padding: 18px 0;
  border-top: 1px dashed rgba($col-gray-d, 0.1);
}
.TotalData__chartTitle {
  color: $col-gray-d;
  font-size: 12px;
  line-height: 16px;
}
.TotalData__horizontalChart {
}

.PercentChart {
  position: relative;
  padding-right: 24px;
}

.PercentChart__title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 40px;
  padding-left: 24px;
  margin-bottom: 10px;
}

.PercentChart__chart {
  width: 100%;
  height: 136px;
  position: relative;
}

.PercentChart__lineChart {
  position: absolute;
  z-index: var(--zindex-percentchart-linechart);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.PercentChart__legend {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: var(--zindex-percentchart-legend);
}
