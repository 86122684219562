@import '../../../colors';

.ToolTip {
  //position: absolute;
  transition: opacity 0.2s linear;
  z-index: var(--zindex-tooltip);
  //opacity: 0;
  padding: 16px 16px;
  background-color: $col-blue-d;
  border-radius: 8px;
  pointer-events: none;
}

._active {
  opacity: 1;
  z-index: var(--zindex-tooltip-active);
}
