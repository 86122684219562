@import '../../../colors';

.ExternalLink {
  color: $col-chart-blue;
  text-decoration: none;

  &:hover {
    color: darken($col-chart-blue, 10%);
  }
}
