@import '../../../colors';
@import '../../../vars';

._success {
  color: $col-success;
}
._warn {
  color: $col-warn;
}
._error {
  color: $col-error;
}
._default {
  color: $col-black;
}

.CardSmallLayout {
  height: 150px;
  width: 215px;
  border-radius: 12px;
  padding: 8px 15px;
  background-color: $col-white;
  overflow: hidden;
  position: relative;
}

.CardSmallLayout__link {
  @extend %link-reset;
}

.CardSmallLayout__spinner {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

._dark {
  background-color: rgba(17, 32, 60, 0.1);
}

.CardStatus {
}

.CardStatus__status {
  margin-top: 8px;
  letter-spacing: 0.4px;
  font-size: 10px;
  height: 14px;
  font-weight: 700;
}

.CardStatus__svg {
  width: 12px;
  height: 12px;
}

.CardStatus__title {
  height: 22px;
  margin-top: 10px;
  color: $col-blue-d;
  font-weight: 700;
  font-size: 16px;
}

.CardStatus__article {
  height: 36px;
  color: $col-blue-d;
  font-size: 14px;
  line-height: 18px;
  margin-top: 4px;
  margin-right: -10px;
  text-overflow: ellipsis;
}

.CardTitle {
  @extend %button-reset;

  .CardTitle__layout {
    transition: background-color 0.2s;
  }

  &:hover {
    .CardTitle__layout {
      background-color: $col-light-background;
    }
  }

  &:focus {
    .CardTitle__layout {
      background-color: $col-darker;
    }
    .CardTitle__layout._active {
      background-color: $col-white;
    }
  }

  .CardTitle__layout._active {
    background-color: $col-darker;
  }
}

.CardTitle__number {
  height: 16px;
  width: 16px;
  background-color: $col-light-background;
  color: $col-gray-d;
  margin-top: 11px;
  border-radius: 50%;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.CardTitle__title {
  margin-top: 16px;
  height: 56px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: $col-blue-d;
  line-height: 18px;
}

.CardDownload {
  cursor: pointer;
}

.CardDownload__layout {
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($col-white, 10%);
  }
}

.CardDownload__link {
  @extend %button-reset;
  display: block;
  text-decoration: none;
}

.CardDownload__icon {
  display: inline-block;
  margin-top: 4px;
  color: $col-chart-blue;
  svg {
    height: 16px;
    width: 16px;
  }
}

.CardDownload__article {
  margin-top: auto;
  font-size: 14px;
  color: $col-blue-d;
  line-height: 18px;
}

.CardCharts {
  color: $col-white;
}

.CardCharts__background {
  background-color: rgba(0, 183, 174, 1);
}

.CardCharts__title {
  height: 36px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  overflow: hidden;
}

.CardCharts__main {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.CardCharts__main__total {
  font-size: 32px;
  line-height: 1;
  margin-top: -2px;
  white-space: nowrap;
}

.CardCharts__about {
  margin-left: 5px;
}

.CardCharts__main__percent {
  font-size: 14px;
  line-height: 16px;
}

.CardCharts__main__title {
  font-weight: 700;
  font-size: 8px;
  line-height: 14px;
  white-space: nowrap;
  text-transform: uppercase;
  opacity: 0.5;
}

.CardCharts__bottom {
  margin-top: 8px;
  display: flex;
}

.CardCharts__bottom__item + .CardCharts__bottom__item {
  margin-left: 22px;
}

.CardCharts__bottom__percent {
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
}

.CardCharts__bottom__title {
  font-weight: 700;
  font-size: 8px;
  line-height: 16px;
  white-space: nowrap;
  text-transform: uppercase;
  opacity: 0.5;
}

// TOTAL

.CardTotal {
  position: relative;
}

.CardTotal__background {
  background-color: rgb(30, 96, 238);
}

.CardTotal__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.CardTotal__table {
  width: calc(100% + 4px);
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;

  th {
    vertical-align: bottom;
  }

  th,
  td {
    &:not(:first-child) {
      width: 20%;
    }
    &.percent {
      width: 10%;
    }
    &.wrap {
      white-space: normal;
    }
  }
}

.CardTotal__headRow {
  font-weight: 700;
  font-size: 8px;
  line-height: 10px;
  opacity: 0.5;
}

.CardTotal__tableHeaderCell {
  padding: 4px 2px;
  padding-bottom: 6px;
  text-align: right;
  white-space: nowrap;
  text-transform: uppercase;

  &:first-child {
    padding: 0;
  }
  &.percent {
    padding-left: 1px;
  }
}

.CardTotal__tableCell {
  padding: 6px 0;
  vertical-align: middle;

  &:first-child {
    font-size: 8px;
  }
  &:not(:first-child) {
    padding: 4px 2px;
    text-align: right;
  }
  &.percent {
    padding-left: 1px;
    opacity: 0.5;
  }
}

.CardTotal__row {
  font-size: 10px;
  line-height: 6px;
}

// CASH_EXEC

.CardTotal.cash_exec {
  .CardTotal__table {
    th,
    td {
      &:not(:first-child) {
        width: 1%;
      }
    }
    th {
      padding: 0;
      padding-right: 4px;
    }
    td {
      &:not(:first-child) {
        padding: 6px 4px;
      }
    }
  }
  .CardTotal__headRow {
    line-height: 16px;
  }
  .CardTotal__row {
    font-size: 14px;
    line-height: 16px;
  }
  .CardTotal__tableCell {
    font-size: inherit;
  }
}
