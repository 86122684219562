@import '../../vars';

.TopBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: var(--nav-bar-width);
  background: $col-white;
  z-index: var(--zindex-topbar);
  box-shadow: 3px 2px 12px -4px rgba($col-black, 0.09);
}
.TopBar__inner {
  @extend %flex;
  align-items: center;
  height: var(--top-bar-height);
  padding-left: var(--page-content-side-padd);
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .TopBar__inner {
      padding-left: var(--page-content-side-padd);
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .TopBar {
      padding-left: 0;
    }
    .TopBar__inner {
      width: 100%;
      padding-right: var(--page-content-side-padd);
      justify-content: space-between;
    }
  }
}
