@import '../../../vars';

.ZoomButton {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: $col-gray-d;
  background-color: $col-white;

  &._plus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &._minus {
    margin-top: 1px;
    //border-top: 1px solid $col-gray;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.ResetButton {
  display: flex;
  width: 40px;
  height: 40px;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
  color: $col-gray-d;
  background-color: $col-white;
}

.RegionMap {
  position: relative;
  width: 100%;
  height: 100%;
  background: none;
  z-index: var(--zindex-regionmap);

  :global {
    .leaflet-tooltip {
      //max-width: 400px;
    }
    .leaflet-top .leaflet-control {
      margin-top: 0;
    }
    .leaflet-top {
      top: 50%;
      transform: translateY(-50%);
    }
    .leaflet-left {
      left: auto;
      right: 24px;
    }
    .leaflet-control-zoom {
      border: none !important;
      border-radius: 8px !important;
      overflow: hidden;
    }
    .leaflet-control-zoom a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-family: $fonts-default;
      color: $col-gray-d;
      &:hover {
        background-color: darken($col-white, 10%);
      }
    }
  }
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .RegionMap {
      :global {
        .leaflet-top {
          top: auto;
          bottom: 300px;
          transform: none;
        }
      }
    }

    @media (max-height: 700px) {
      .RegionMap {
        :global {
          .leaflet-top {
            bottom: 210px;
          }
          .leaflet-left {
            right: var(--page-content-side-padd);
          }
        }
      }
    }
  }
}
