@import '../../vars';

.RoadmapEventPopup {
  max-width: 635px;
  height: 80%;
  min-height: 400px;
}

.RoadmapEventPopup__swipeBox {
  padding: 0 var(--page-content-side-padd);
}

.RoadmapEventPopupContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 8px;
}

.title__gray {
  margin-left: 8px;
  color: $col-gray-d;
}

.description {
  @include bodyText;
  white-space: pre-line;
}

.searchInput {
  height: 48px;
}

.itemsContainer {
  flex-grow: 1;
  overflow-y: scroll;
  margin-left: calc(-1 * var(--popup-padding-side));
  margin-right: calc(-1 * var(--popup-padding-side));
  margin-bottom: calc(-1 * var(--popup-padding-bottom));
  padding: 40px var(--popup-padding-side) var(--popup-padding-bottom);
}

.region {
  border-top: 1px solid $col-transparent-background;
}

.RegionDropdownContent {
  margin-bottom: 24px;
}

.RegionDropdownContent__document {
  display: flex;
  justify-content: space-between;

  & + & {
    margin: 16px 0 0 0;
  }

  > * {
    margin-right: 8px;
  }
}

.RegionDropdownContent__about {
  flex-grow: 1;
  font-size: 12px;
  line-height: 16px;
}

.RegionDropdownContent__filename {
  font-weight: 700;
}

.RegionDropdownContent__status {
  margin-top: 8px;
}

.RegionDropdownContent__filesize {
  min-width: 48px;
  font-size: 12px;
  line-height: 16px;
  color: $col-gray-d;
  text-align: right;
}

.RegionDropdownContent__link {
  width: 28px;
  height: 28px;
  margin-top: -6px;
  color: $col-blue;
}

.Popup {
  position: absolute;
}

.PopupOverlay {
  z-index: var(--zindex-map-filters-popup);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
