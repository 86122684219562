@import '../../colors';

$scrollBtnSize: 32px;
$buttonOffset: 16px;

.scrollContainer {
  position: relative;

  &._dragging {
    user-select: none;
  }

  > div:first-child,
  > div:first-child > div:first-child {
    min-height: 0 !important;
  }

  > div:first-child > div:first-child {
    margin-right: 0 !important;
    overflow: hidden !important;
    overflow-x: scroll !important;
  }

  &._macos {
    .horizontalScrollTrack {
      display: none;
    }
  }
  li {
    list-style-type: none;
  }
}

.scrollListWrapper {
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
}
.scrollList {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;

  &._centered {
    justify-content: center;
    align-items: center;
  }
}
.scrollList__item {
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
}

.horizontalScrollTrack {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px !important;
  border-radius: 4px;
  background: $col-white;
  cursor: pointer;
}

.horizontalScrollWrapp {
  position: relative;
}

.horizontalScrollThumb {
  position: absolute;
  top: 0;
  height: 4px !important;
  border-radius: 4px;
  background-color: rgba($col-blue, 0.5);
  cursor: pointer;
  &:hover {
    background-color: $col-blue;
  }
}

.scrollNav__btn {
  //display: none;
  position: absolute;
  z-index: var(--zindex-scrollnav-btn);
  top: 50%;
  margin-top: -($scrollBtnSize / 2);
  user-select: none;

  // ._edge (default)
  &._left {
    left: 0;
    margin-left: -($scrollBtnSize / 2);
  }
  &._right {
    right: 0;
    margin-right: -($scrollBtnSize / 2);
  }

  &._inside {
    &._left {
      left: $buttonOffset;
      margin-left: 0;
    }
    &._right {
      right: $buttonOffset;
      margin-right: 0;
    }
  }

  &._outside {
    &._left {
      left: -$buttonOffset;
      margin-left: -$scrollBtnSize;
    }
    &._right {
      right: -$buttonOffset;
      margin-right: -$scrollBtnSize;
    }
  }
}

.scrollBtn {
  position: relative;
  width: $scrollBtnSize;
  height: $scrollBtnSize;
  background: #edf2f4;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s;

  &._darker {
    background: $col-darker;
  }

  &._left {
    .scrollBtn__icon {
      transform: scale(-1, 1);
    }
  }
  &._right {
  }

  svg path[class] {
    fill: #315efb;
  }

  &:hover {
    background: darken(#edf2f4, 5);
  }
}
.scrollBtn__icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
}
