@import '../../../vars';

.CardView {
  padding: 0 var(--page-content-side-padd);
  width: 100%;
}

.CardView__filter {
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
.CardView__filterWrapper {
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
}
.CardView__filterItems {
  display: flex;
}

.CardView__item {
  margin-right: 14px;

  &:last-child {
    margin-right: 0;
  }
}

:export {
  success: $col-success;
  error: $col-error;
  warn: $col-warn;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .CardView__filter {
      padding: 0 var(--page-content-side-padd);
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
    }
  }
}
