@import '../../../colors';

.TooltipChart {
  min-width: 148px;
  color: $col-white;
}

.TooltipChart__title {
  margin-left: 20px;
  margin-bottom: 6px;
}

.TooltipChart__item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 18px;
  & + & {
    margin-top: 14px;
  }
}

.TooltipChart__actualityDate {
  margin-top: 14px;
}

.TooltipChart__icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  color: $col-success;
}

.TooltipChart__name {
  display: flex;
  align-items: center;
  margin-right: 36px;
}

.TooltipChart__value {
}
