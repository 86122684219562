@import '../../../vars';

.RegionProjects__title {
  margin-bottom: 10px;
}
.RegionProjects__filters {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.RegionProjects__filter {
  flex-grow: 1;
  //max-width: 180px;
}
.RegionProjects__actualityDate {
  color: rgba($col-gray-d, 0.6);
  margin-left: 16px;
  p {
    font-weight: 500;
  }
}

.RegionProjects__filterSelect {
  width: 100%;
}

.RegionProjects__chart {
  @extend %link-reset;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
}
