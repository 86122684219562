@import '../../colors';
@import '../../vars';

$col-secondary-hover: rgb(233, 234, 237);

.TaskStatus__dropdown {
  margin-top: 16px;
}
.TaskStatus__header {
  @extend %button-reset;
  border-radius: 12px;
  background-color: $col-white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 24px 18px;
  position: relative;
  z-index: var(--zindex-taskstatus-header);

  &:hover,
  &:focus {
    background-color: $col-secondary-hover;
  }

  &:active {
    background-color: darken($col-secondary-hover, 10%);
  }

  &._opened {
    border-radius: 12px 12px 0 0;
  }
}
.TaskStatus__title {
  font-size: 14px;
  line-height: 18px;
}
.TaskStatus__points {
  width: 30px;
  min-width: 30px;
  flex-basis: 30px;
  height: 30px;
  position: relative;
}

.TaskStatus__points_text {
  color: $col-gray-d;
  font-size: 8px;
  width: 30px;
  height: 30px;
  font-weight: 700;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TaskStatus__droppedMenu {
  position: relative;
  top: -16px;
  height: calc(100% + 16px);
  padding-top: 16px;
  background: $col-transparent-background;
  border-radius: 0 0 12px 12px;
  z-index: var(--zindex-taskstatus-droppedmenu);
}
.TaskStatus__menu {
  padding: 18px 16px;
  display: flex;
  align-items: center;
}
.TaskStatus__check {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 20px;
  &._success {
    color: $col-success;
  }
  &._error {
    color: $col-error;
  }

  @extend %svg-icon;
}

.TaskStatus__information {
  flex-grow: 1;
}
.TaskStatus__data {
  display: flex;
  margin-top: 8px;
}
.TaskStatus__title {
  font-size: 12px;
  line-height: 18px;
  margin-right: 14px;
}
.TaskStatus__subjects {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: $col-blue;
  margin-right: 22px;
}
.TaskStatus__values {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  margin-right: 22px;
}

.TaskStatus__values__title {
  color: $col-gray-d;
  margin-right: 8px;
}

.TaskStatus__documents {
  margin: 0 16px;
  color: $col-chart-blue;
}

.TaskStatus__button {
  width: 279px;
  margin: 24px auto;
}

.RoadmapEventsList {
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}
.RoadmapEventsList__Placeholder {
  text-align: center;
  color: $col-gray-d;
}

.ControlPointsList__events {
  margin-top: 16px;
  margin-left: 20px;
  > * {
    margin-bottom: 16px;
  }
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .TaskStatus__values {
      display: block;
    }
  }
}
