.ChartWithLegend {
  position: relative;
  width: 100%;
  height: 100%;
}

.ChartWithLegend__legend {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ChartWithLegend__chart {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
