$col-white: rgb(255, 255, 255);
$col-black: rgb(0, 0, 0);
$col-gray: rgb(208, 208, 208);
$col-gray-lighten: lighten($col-gray, 5%);
$col-gray-d: rgb(108, 123, 153);
$col-gray-l: rgb(245, 245, 247);
$col-violet: rgb(71, 48, 122);
$col-blue: rgb(50, 80, 187);
$col-blue-d: rgb(13, 27, 53);
$col-blue-d2: rgb(8, 17, 34);
$col-blue-l: rgb(41, 151, 212);
$col-green: rgb(0, 142, 131);
$col-magenta: rgb(164, 3, 111);
$col-magenta-l: rgb(204, 64, 110);
$col-orange: rgb(181, 106, 8);
$col-yellow: rgb(214, 152, 52);
$col-darker: rgb(228, 229, 235);

$col-chart-purple: rgb(105, 71, 252);
$col-chart-blue: rgb(61, 104, 227);
$col-chart-blue2: rgb(21, 69, 242);
$col-chart-dash-line: rgba(90, 109, 176, 0.33);
$col-chart-green: rgba(97, 194, 96, 1);
$col-chart-green-l: rgba(97, 220, 98, 1);

$col-light-background: rgb(240, 241, 244);
$col-transparent-background: rgba(108, 123, 153, 0.1);

$col-success: rgb(87, 179, 86);
$col-success-lighten: lighten($col-success, 10%);
$col-success-lighten2: lighten($col-success-lighten, 5%);
$col-success-lighten3: lighten($col-success-lighten, 10%);

$col-warn: rgb(255, 149, 19);
$col_warn_lighten: lighten($col-warn, 10%);
$col_warn_lighten2: lighten($col-warn-lighten, 5%);
$col_warn_lighten3: lighten($col-warn-lighten, 10%);

$col-error: rgb(239, 55, 55);
$col-error-lighten: lighten($col-error, 10%);
$col-error-lighten2: lighten($col-error-lighten, 5%);
$col-error-lighten3: lighten($col-error-lighten, 10%);

$col-map-fill: rgba(87, 179, 86, 0.5);
$col-map-border: rgba(87, 179, 86, 1);

$col-text-d: $col-blue-d;

:export {
  white: $col-white;
  black: $col-black;

  gray: $col-gray;
  grayLighten: $col-gray-lighten;
  grayDark: $col-gray-d;
  grayLight: $col-gray-l;
  violet: $col-violet;
  blue: $col-blue;
  blueDark: $col-blue-d;
  blueDark2: $col-blue-d2;
  blueLight: $col-blue-l;
  green: $col-green;
  magenta: $col-magenta;
  magentaLight: $col-magenta-l;
  orange: $col-orange;
  yellow: $col-yellow;

  chartPurple: $col-chart-purple;
  chartBlue: $col-chart-blue;
  chartBlue2: $col-chart-blue2;
  chartDashLine: $col-chart-dash-line;
  chartGreen: $col-chart-green;
  chartGreenL: $col-chart-green-l;

  lightBackground: $col-light-background;

  success: $col-success;
  successLighten: $col-success-lighten;
  successLighten2: $col-success-lighten2;
  successLighten3: $col-success-lighten3;

  warn: $col-warn;
  warnLighten: $col-warn_lighten;
  warnLighten2: $col-warn_lighten2;
  warnLighten3: $col-warn_lighten3;

  error: $col-error;
  errorLighten: $col-error-lighten;
  errorLighten2: $col-error-lighten2;
  errorLighten3: $col-error-lighten3;

  mapFill: $col-map-fill;
  mapBorder: $col-map-border;
}
