@import '../../../_vars';
@import '../../../colors';

$col-darker: rgb(223, 224, 227);

.searchInput {
  height: 56px;
  min-width: 330px;
  position: relative;
  outline: none;
  display: flex;

  &._withSearchBtn {
    .searchInput__input {
      border-radius: 8px 0 0 8px;
    }
  }
}

.searchInput__inputBox {
  flex-grow: 1;
  position: relative;
}

.searchInput__searchBtn {
  flex-basis: auto;
  flex-grow: 0;
  width: 128px;
  border-radius: 0 8px 8px 0;
  height: 100%;
}

@media (max-width: $bp-mobile-small-max-width) {
  .searchInput {
    min-width: 228px;
  }
  .searchInput__searchBtn {
    width: 60px;
  }
}

.searchInput__icon {
  position: absolute;
  height: 18px;
  width: 18px;
  top: calc(50% - 9px);
  left: 16px;
  path {
    fill: $col-gray-d;
  }
}

.searchInput__input {
  height: 100%;
  width: 100%;
  background-color: $col-light-background;
  padding-left: 44px;
  padding-right: 16px;
  border-radius: 8px;
  font-size: 14px;
  color: $col-blue-d;
  outline: none;
  text-decoration: none;
  border: 0;
  font-family: inherit;

  &:focus,
  &:active {
    background-color: darken($col-light-background, 2%);
  }

  &:disabled {
    opacity: 0.5;
  }
  &::placeholder {
    opacity: 0.5;
    color: $col-blue-d;
  }
}

.searchInput__close {
  position: absolute;
  height: 24px;
  width: 24px;
  transform: translateY(-50%);
  top: 50%;
  right: 12px;
  cursor: pointer;
  padding: 3px;
}

.searchInput._darker {
  .searchInput__input {
    background-color: $col-darker;

    &:focus,
    &:active {
      background-color: lighten($col-darker, 2%);
    }
  }
}

._small {
  height: 40px;
}
