@import '../../../vars';
@import '../../../colors';

.MainChart {
  margin: 24px 0 0;
  width: 100%;
  max-width: 530px;
  min-height: 408px;
  border-radius: 12px;
  background: $col-white;
  position: relative;
  padding: 24px 16px;
}

.MainChart__tabs {
  display: flex;
  margin-bottom: 24px;
  padding-left: calc(var(--result-page-side-padd) - 16px);
}

.MainChart__info {
  display: flex;
  padding-left: calc(var(--result-page-side-padd) - 16px);
}

.MainChart__block {
  &:not(:empty) {
    margin-right: 24px;
  }
}

.MainChart__number {
  display: flex;
  align-items: center;
  font-size: 42px;
  font-weight: 100;
  line-height: 50px;
}

.MainChart__dots {
  height: 6px;
  width: 6px;
  margin-right: 6px;
  background: $col-gray-d;
  border-radius: 50%;
  &._blue {
    background: $col-chart-blue;
  }
}

.MainChart__text {
  margin-left: 16px;
  font-size: 12px;
  line-height: 16px;
}

.MainChart__chart {
  width: 100%;
  height: 216px;
  margin-top: 16px;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .MainChart {
      max-width: none;
    }
  }

  @media (min-width: $bp-desktop-min-width) {
    .MainChart {
      max-width: none;
    }
  }
}
