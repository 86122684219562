@import '../../vars';

.MainChart {
  padding: 25px 45px 64px;
  background: $col-blue;
}

.MainChart__filters {
  margin-bottom: 44px;
}
.MainChart__filter {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}
.MainChart__filterRow {
  display: flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: 16px;
  }
}

.MainChart__filterSelect {
  width: 100%;
}
.MainChart__filterPeriodSelect {
  //width: 120px;
}

.MainChart__view {
  position: relative;
  height: 180px;
}

.MainChart__legendItem {
  color: white;
}
