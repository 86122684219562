@import './colors';

$font-museo: 'museo';
$font-roboto: 'Roboto';
$fonts-default: $font-museo, -apple-system, BlinkMacSystemFont, sans-serif,
  $font-roboto;

// breakpoints

// TODO mobile 450px && tablet 781px

$bp-mobile-small-max-width: 400px;

$bp-mobile-min-width: 320px;
$bp-mobile-max-width: 600px;

$bp-tablet-min-width: $bp-mobile-max-width + 1px;
$bp-tablet-max-width: 781px;

$bp-tablet-land-min-width: $bp-tablet-max-width + 1px;
$bp-tablet-land-max-width: 1024px;

$bp-sm-desktop-min-width: $bp-tablet-land-max-width + 1px;
$bp-sm-desktop-max-width: 1280px - 1px;

$bp-desktop-min-width: $bp-sm-desktop-max-width + 1px;
$bp-desktop-max-width: 1440px - 1px;

$bp-lg-desktop-min-width: $bp-desktop-max-width + 1px;

$bp-xlg-desktop-min-width: 1600px;

:root {
  --grid-col-amount: 12;
  --grid-col-width: 52px;
  --grid-col-gutter: 24px;
  --grid-width: calc(
    (var(--grid-col-width) + var(--grid-col-gutter)) * var(--grid-col-amount) -
      var(--grid-col-gutter)
  );

  --top-bar-height: 72px;
  --nav-bar-width: 72px;

  --page-content-vert-padd: 24px;
  --page-content-side-padd: 32px;
  --page-content-bottom-padd: 56px;

  --right-pane-side-padd: 44px;

  --result-min-width: 256px;
  //--result-max-width: 420px;
  --main-card-min-width: 256px;
  //--main-card-max-width: 400px;

  --result-page-side-padd: 40px;

  --login-page-padding: 48px;
  --login-page-max-width: 426px;

  --button-height: 48px;

  --right-pane-width: 389px;

  --swipebox-content-side-padd: 24px;
  --swipebox-content-bottom-padd: 34px;
  --swiper-height: 40px;

  // Z-INDEX

  --zindex-topbar: 103;
  --zindex-navbar: 104;
  --zindex-flyingbox: 100;
  --zindex-swipebox: 102;
  --zindex-swipebox-offset: -999;
  --zindex-swipebox-offset-opened: 100;
  --zindex-map-filters-popup: 10000;
  --zindex-mapview-region-map: 1;
  --zindex-mapview-map: 1;
  --zindex-mapview-content: 2;
  --zindex-mapview-overlay: 2;
  --zindex-mapview-close: 100;
  --zindex-goals: 105;
  --zindex-bg-wrapper: 1;
  --zindex-bg-wrapper-active: 99;
  --zindex-subject-view: 98;
  --zindex-downloadbtn-tt: 105;
  --zindex-subsidview-filters: 10;
  --zindex-chartview-planets: 2;
  --zindex-chartview-planet: 500;
  --zindex-chartview-piecharts: 3;
  --zindex-chartview-minvalue: 10;
  --zindex-chartview-title: 2;
  --zindex-chartview-loader: 10;
  --zindex-tooltip: -1000;
  --zindex-tooltip-active: 1000;
  --zindex-indicatorsview: 98;
  --zindex-progresssview: 98;
  --zindex-taskstatus-header: 3;
  --zindex-taskstatus-droppedmenu: 2;
  --zindex-roadmap-event: 3;
  --zindex-scrollnav-btn: 1;
  --zindex-regionmap: 1;
  --zindex-popover: 1600;
  --zindex-spinner-absolute: 1000;
  --zindex-percentchart-linechart: 3;
  --zindex-percentchart-legend: 2;
  --zindex-select-menu: 20;

  --zindex-indexpage-topbar: 5;
  --zindex-indexpage-closebtn: 5;
  --zindex-indexpage-charttabs: 4;
  --zindex-cardsview: 1;
  --zindex-cardsview-loader: 1;
  --zindex-cardsview-shadow: 10;
  --zindex-mainchart-popup: 15;
  --zindex-mainchart-popup-close: 101;
  --zindex-indexpage-chart: 3;
}

@function px($v) {
  @return $v / 16 * 1em;
}
@function rpx($v) {
  @return $v / 16 * 1rem;
}
@function invert($v) {
  @return calc(-1 * #{$v});
}

@mixin gridLayout12 {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(var(--grid-col-amount), var(--grid-col-width));
  grid-column-gap: var(--grid-col-gutter);
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0 auto;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
@mixin horizontallyCenteredImage {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 100%;
  right: 0;
  transform: translateX(-50%);
}
@mixin verticallyCenteredImage {
  position: absolute;
  top: 0;
  margin: auto;
  bottom: 0;
}
@mixin buttonReset {
  padding: 0;
  border: none;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  background: none;
  text-align: left;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

@mixin headingText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

@mixin bodyText {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

%flex {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
%flex-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
%flex-btw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
%flex-str {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
%clear {
  &:before,
  &:after {
    display: table;
    content: '';
    height: 0;
    opacity: 0;
    visibility: hidden;
    clear: both;
  }
}

%list-simple {
  margin: 0;
  padding: 0;
  list-style: none;
}

%button-reset {
  @include buttonReset;
}
%svg-icon {
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
%link-reset {
  display: block;
  color: inherit;
  text-decoration: none;
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }
}

:export {
  mediaWidthSmallMobile: $bp-mobile-small-max-width;
  mediaWidthMobile: $bp-mobile-max-width;
  mediaWidthTablet: $bp-tablet-max-width;
  mediaWidthTabletLand: $bp-tablet-land-max-width;
}

@media only screen {
  @media (max-width: $bp-sm-desktop-max-width) {
    :root {
      --right-pane-width: 350px;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    :root {
      --nav-bar-height: 56px;

      --page-content-side-padd: 16px;

      --right-pane-width: 100%;

      --zindex-navbar: 101;
      --zindex-swipebox-offset-opened: 101;
      --zindex-mainchart-popup: 100;
    }
  }

  @media (min-width: $bp-lg-desktop-min-width) {
    :root {
      --grid-col-width: 86px;
      --grid-col-gutter: 24px;

      --right-pane-width: 480px;
      --zindex-cardsview: 10;
    }
  }
}
