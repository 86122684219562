@import '../../vars';

.ChartView {
  position: relative;
  width: 100%;
}

.ChartView__wrapper {
  background: $col-white;
  width: 100%;
  //max-width: 530px;
  height: 416px;
  padding: 24px;
  border-radius: 8px;
}
.ChartView__content {
  position: relative;
}
.ChartView__inner {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
}
.ChartView__loader {
  padding-top: 56px;
}

.ChartView__tabs {
  display: flex;
}

.ChartView__tab {
  width: 285px !important;
  margin-right: 16px;
}

.ChartView__selects {
  display: flex;
  margin-top: 9px;

  > *:not(:last-child) {
    margin-right: 16px;
  }
}

.ChartView__select {
  width: 176px;
  :global .select__control {
    height: 40px;
  }
}

.ChartView__grbs {
  width: 150px;
}

.ChartView__scroll {
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
.ChartView__chart {
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
  position: relative;
  height: 192px + 55px;
  width: 100%;
  min-width: $bp-mobile-min-width;
}

.ChartView__legend {
  position: absolute;
  left: 0;
  top: 24px - 6px;
}

.ChartView__chartBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  position: absolute;
  left: 60px;
  right: 0;
  top: 0;
  bottom: 0;
}

.ChartView__minValue {
  position: relative;
  z-index: var(--zindex-chartview-minvalue);
  pointer-events: none;
  margin-top: 24px;
  g {
    pointer-events: auto;
  }
  text {
    font-size: 8px;
    font-weight: 700;
    text-anchor: end;
  }
  line {
    stroke: $col-black;
    stroke-dasharray: 4px;
  }
}

.ChartView__chartItem {
  position: relative;

  &._inactive {
    opacity: 0.7;
  }
}

.ChartView__chartItemWrapper {
  position: relative;
}

.ChartView__chartTitle {
  position: absolute;
  width: 100%;
  top: calc(100% + 8px);
  left: 0;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: $col-gray-d;
}

.ChartView__chartTitleButton {
  @include buttonReset;
  text-align: center;

  &:hover,
  &:focus {
    color: $col-chart-purple;
  }
}

.ChartView__additionalLink {
  @extend %link-reset;
  margin-top: 4px;
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 12px;
  font-weight: 700;
  color: $col-blue;
  text-transform: uppercase;
}

@media only screen {
  @media (max-width: $bp-tablet-land-max-width) {
    .ChartView__tabs {
      margin-left: -24px;
      margin-right: -24px;
    }
    .ChartViewTabsSlider {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  @media (max-width: $bp-tablet-max-width) {
    .ChartView__wrapper {
      height: auto;
      min-height: 416px;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .ChartView__wrapper {
      width: calc(100% + var(--page-content-side-padd) * 2);
      margin-left: calc(var(--page-content-side-padd) * -1);
      margin-right: calc(var(--page-content-side-padd) * -1);
      border-radius: 0;
    }

    .ChartView__chart {
      height: 192px + 60px;
    }
    .ChartView__content {
      margin-left: -24px;
      margin-right: -24px;
    }
    .ChartView__scroll {
      padding: 0 24px;
    }
  }
}
