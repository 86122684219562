@import '../../../colors';
@import '../../../vars';

.ProjectIndicator {
  color: $col-gray-d;

  &._success {
    color: #57b356;

    .ProjectIndicator__background {
      color: rgba($col-success, 0.1);
    }
  }

  &._warn {
    color: #ff9513;
    .ProjectIndicator__background {
      color: rgba($col-warn, 0.1);
    }
  }

  &._error {
    color: #ef3737;
    .ProjectIndicator__background {
      color: rgba($col-error, 0.1);
    }
  }
}

.ProjectIndicator__background {
  color: rgba($col-gray-d, 0.1);
}

.ProjectSelector {
  display: flex;
  align-items: center;
  font-size: 12px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font-family: $fonts-default;
  text-align: left;
  width: 164px;

  .ProjectSelector__number {
    color: $col-gray-d;
    background-color: rgba($col-gray-d, 0.1);
    transition: color 0.2s linear, background-color 0.05s linear;
  }
  .ProjectSelector__title {
    color: $col-gray-d;
    margin-left: 9px;
    transition: color 0.2s linear, background-color 0.05s linear;
  }

  &:hover,
  &:focus {
    .ProjectSelector__number {
      color: $col-blue-d;
      background-color: rgba($col-blue-d, 0.1);
    }

    .ProjectSelector__title {
      color: rgb(8, 17, 34);
    }
  }

  &._active {
    font-weight: 700;

    .ProjectSelector__number {
      color: $col-blue-d;
      background-color: rgba($col-blue-d, 0.1);
    }
    .ProjectSelector__title {
      color: rgb(8, 17, 34);
    }
  }
}

.ProjectSelector__number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  background-color: rgba(13, 27, 53, 0.1);
  border-radius: 50%;
}

.ProjectSelector__circle {
  width: 24px;
  height: 24px;
  stroke-width: 1px;
}

.ProjectSelector__title {
  margin-left: 9px;
  line-height: 16px;
}
