@import '../../../vars';

.CardsScroll {
  margin-left: calc(-1 * var(--page-content-side-padd));
  margin-right: calc(-1 * var(--page-content-side-padd));
}

.CardsScroll__scroll {
  width: 100%;

  :global .__scrollListWrapper {
    padding: 1em var(--page-content-side-padd);
  }
}

.CardsScroll__list {
}

.CardsScroll__item {
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
}
