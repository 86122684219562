@import '../../../vars';

.MapCards {
  width: 100%;
}

.MapCards__scroll {
  width: 100%;
  ul {
    margin: 0;
  }

  :global .__scrollListWrapper {
    padding: 1em var(--page-content-side-padd);
  }
}

.MapCards__list {
  //@extend %flex;
  //align-items: flex-start;
}
.MapCards__item {
  margin-right: 24px;
  &:last-child {
    margin-right: 0;
  }
}
