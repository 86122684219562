@import '../../../vars';

.Indicators {
  margin-top: 36px;
}
.Indicators__title {
  margin-bottom: 8px;
}
.Indicators__filter {
  margin-bottom: 28px;
}
.Indicators__filterSelect {
  width: 100%;
}

.Indicator {
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px dashed $col-gray-d;
  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
}

.Indicator__title {
  color: $col-gray-d;
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    margin-bottom: 12px;
  }
}
