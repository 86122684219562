@import '../../vars';

.FlyingButton {
  @extend %flex-c;
  width: 100%;
  padding: 0 18px;
  font-weight: 700;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
}

.FlyingBox {
  position: fixed;
  z-index: var(--zindex-flyingbox);
  bottom: 0;
  left: 0;
  width: 100%;

  padding: 0 var(--page-content-side-padd) 8px;
}

.FlyingBox__content {
  max-width: 450px;
  margin: 0 auto;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .FlyingBox {
      padding-left: calc(var(--nav-bar-width) + var(--page-content-side-padd));
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .FlyingBox {
      padding-left: var(--page-content-side-padd);
      margin-bottom: var(--nav-bar-height);
    }
  }
}
