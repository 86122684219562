@import '../../vars';
@import '../../colors';

.MainPage {
  position: relative;
  background: $col-blue-d2;
}
.MainGridLayout {
  position: relative;
  padding-top: 0;
  grid-template-rows: var(--top-bar-height) 72px minmax(1px, auto);
}

.TopBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 0;
  background: $col-blue-d2;
  z-index: var(--zindex-indexpage-topbar);
  opacity: 1;
  transition: opacity 0.2s;
  &._opaque {
    opacity: 0.1;
    pointer-events: none;
  }
}

.TopBar__inner {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: var(--grid-width);
  height: var(--top-bar-height);
  margin: 0 auto;
}

.CloseBtn {
  padding: 0;
  border: none;
  color: white;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  z-index: var(--zindex-indexpage-closebtn);
  pointer-events: auto;
}

.TopBar__date {
  display: flex;
  margin-right: 98px;
  pointer-events: auto;
}

.TopBar__select {
  background: rgba($col-gray-d, 0.1);
  border-radius: 8px;
  color: $col-white;
  width: 100px;
  --height: 40px !important;
  :global .select__single-value {
    color: $col-white;
  }
}

.TopBar__selectLayout {
  margin-right: 16px;
}

.TopBar__datePicker {
  background: rgba($col-gray-d, 0.1);
  color: $col-white;
}

.TopBar__section {
  display: flex;
}

.MonthFilter {
  width: auto;
}
.TopBar__select_date {
  width: 176px;
}

.TopBar__item {
  @extend %button-reset;
  color: $col-gray-d;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
  & + & {
    margin-left: 22px;
  }
  &._active {
    color: $col-white;
  }
}
.TopBar__closeBtn {
  display: flex;
  margin-right: 8px;
  margin-left: auto;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  & > svg {
    flex-shrink: 0;
  }
}

.ChartTabs {
  position: relative;
  z-index: var(--zindex-indexpage-charttabs);
  grid-area: 2 / 1 / 3 / 10;
}
.ChartTabs__tab {
  width: 285px !important;
  padding-right: 16px;
}

.ChartSelected__Btn {
  position: absolute;
  z-index: var(--zindex-mainchart-popup-close);
  top: 72px;
  right: 12px;
}

.MainChartPartPopup {
  display: flex;
  grid-area: 3 / 9 / 4 / 13;
  //min-height: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: var(--zindex-mainchart-popup);
  position: relative;
}

.MainChartPartPopup__inner {
  position: relative;
  display: block;
  overflow-y: auto;
  margin: auto;
  max-height: 100%;
  padding-right: 16px;
  width: 100%;
}

.ChartTableContainer {
}

.ChartTable {
  width: 100%;
  margin-bottom: 32px;
  color: $col-white;
  &._smallPad {
    .ChartTable__headerCell,
    .ChartTable__cell {
      padding: 8px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.ChartTable__headerCell,
.ChartTable__cell {
  padding: 8px 16px;
  vertical-align: top;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  &:nth-child(n + 2) {
    text-align: right;
    white-space: pre-line;
  }
}

.ChartTable__headerCell {
  font-size: 8px;
  line-height: 12px;
  text-align: left;

  &:first-child {
    max-width: 145px;
  }
}

.ChartTable__mainText {
  color: white;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.4;
  transition: opacity 0.2s;
}

.ChartTable__cell {
  padding: 12px 16px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

.ChartTable__cellContent {
  position: relative;
  &._withPadding {
    margin-left: 16px;
  }
}

.ChartTable__cellIcon {
  position: absolute;
  display: block;
  left: -19px;
  top: -1px;
  color: $col-success;
}

.ChartTable__clickable {
  cursor: pointer;
  transition: opacity 0.2s;
  &:not(.ChartTable__active) {
    &:hover {
      opacity: 0.7;
      td {
        opacity: 1;
      }
    }
  }

  &.ChartTable__active:not(.ChartTable__activeMode) {
    &:hover {
      opacity: 0.7;
      td {
        opacity: 1;
      }
    }
  }
}
.ChartTable__active {
  opacity: 1;
  td {
    opacity: 1;
  }
}

.TableContainer__link {
  width: 100%;
  min-height: var(--button-height);
  margin-bottom: 56px;
}

.ChartView {
}

.CardsView {
  position: relative;
  grid-area: 3 / 10 / 4 / 13;
  z-index: var(--zindex-cardsview);
  transition: filter 0.2s ease;
}

.IndexPage__chart {
  grid-area: 3 / 1 / 4 / 10;
  z-index: var(--zindex-indexpage-chart);
  @extend %flex-c;
}

:global {
  ._blurred {
    filter: blur(75px);
    pointer-events: none;
  }
}

@media only screen {
  @media (max-width: $bp-tablet-land-max-width) {
    .MainPage {
      display: block;
    }
    .TopBar__inner {
      width: 100%;
      padding-left: var(--page-content-side-padd);
      padding-right: var(--page-content-side-padd);
    }
    .ChartTabs {
      padding-left: var(--page-content-side-padd);
      padding-right: var(--page-content-side-padd);
    }

    .IndexPage__chart {
      grid-column: 1 / span 8;
    }
    .CardsView {
      grid-column: 9 / span 4;
    }
    .MainChartPartPopup {
      grid-column: 8 / span 5;
    }
  }
  @media (max-width: $bp-tablet-max-width) {
    .MainPage {
      padding-bottom: var(--nav-bar-height);
      overflow: hidden;
      overflow-y: auto;
    }
    .MainGridLayout {
      grid-template-rows: var(--top-bar-height) auto 1fr auto;
    }

    .TopBar__inner {
      justify-content: space-between;
    }
    .TopBar__date {
      margin-right: 0;
    }
    .TopBar__closeBtn {
      position: absolute;
      top: 100%;
      right: var(--page-content-side-padd);
      margin: 0;
      margin-top: 16px;
    }

    .ChartTabs {
      grid-row: 2 / span 1;
      grid-column: 1 / span 1;
    }

    .IndexPage__chart {
      grid-row: 3 / span 1;
      grid-column: 1 / span 1;
      transition: padding 0.3s;

      &._selected {
        padding-right: 280px;
      }
    }

    .CardsView {
      width: 100%;
      grid-row: 4 / span 1;
      grid-column: 1 / span 1;

      &:before,
      &:after {
        display: none;
      }
    }

    .MainChartPartPopup {
      grid-row: 3 / span 2;
      grid-column: 1 / span 1;
      justify-content: flex-end;
      pointer-events: none;
    }
    .MainChartPartPopup__inner {
      width: 280px;
      margin: 0;
      padding-bottom: 30px;
      pointer-events: auto;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .TopBar__inner {
      width: 100%;
      padding-right: var(--page-content-side-padd);
    }
    .TopBar__item {
      svg {
        width: 24px;
        height: 24px;
        margin-right: 0;
      }
      span {
        display: none;
      }

      + .TopBar__item {
        margin-left: 16px;
      }
    }
    .TopBar__closeBtn {
      margin-top: 4px;
    }

    .ChartTabs {
      padding-left: 0;
      padding-right: 0;
    }
    .ChartViewTabsSlider {
      padding-left: var(--page-content-side-padd);
      padding-right: var(--page-content-side-padd);
    }

    .IndexPage__chart {
      padding: 0 var(--page-content-side-padd);
      transition: none;

      &._selected {
        padding-right: var(--page-content-side-padd);
      }
    }

    .MainChartPartPopup {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-top: 70px;
      padding-bottom: var(--nav-bar-height);
      pointer-events: auto;
      background: $col-blue-d;
    }
    .MainChartPartPopup__inner {
      width: 100%;
      padding: 0 var(--page-content-side-padd) 30px;
      padding-left: calc(var(--page-content-side-padd) + 10px);
    }
    .ChartSelected__Btn {
      top: 25px;
      right: var(--page-content-side-padd);
    }
  }
  @media (max-width: $bp-mobile-small-max-width) {
    .TopBar__select {
      width: 94px;
    }
    .TopBar__select_date {
      width: 140px;
    }
  }
  @media (max-width: $bp-mobile-min-width) {
    .TopBar__selectLayout {
      margin-right: 4px;
    }
    .TopBar__select_date {
      width: 130px;
    }
    .TopBar__item {
      svg {
        width: 20px;
        height: 20px;
      }

      + .TopBar__item {
        margin-left: 10px;
      }
    }
  }

  @media (min-width: $bp-lg-desktop-min-width) {
    .MainGridLayout {
      grid-template-rows: minmax(180px, 1fr) minmax(220px, auto) 1fr;
    }

    .TopBar {
      grid-row: 1 / span 1;
      grid-column: 1 / span 2;
      background: none;
      pointer-events: none;
    }
    .TopBar__inner {
      display: block;
      height: auto;
      padding-left: 0;
      padding-top: 40px;
    }
    .TopBar__date {
      margin-right: 0;
      margin-bottom: 40px;
    }
    .Topbar__background {
      display: none;
    }
    .TopBar__select,
    .TopBar__item {
      pointer-events: auto;
    }
    .TopBar__closeBtn {
      margin-right: 250px;
      margin-top: -24px;
      pointer-events: auto;
    }

    .ChartTabs {
      grid-row: 2 / span 1;
      grid-column: 1 / span 2;

      //display: flex;
      //align-items: center;
    }
    .ChartTabs__inner {
      display: block;
    }
    .ChartTabs__tab {
      width: 100%;
      height: auto;
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .IndexPage__chart {
      grid-row: 1 / span 3;
      grid-column: 3 / span 8;
    }
    .ChartView {
      width: 720px;
      height: 720px;
    }

    .CardsView {
      grid-row: 1 / span 3;
      grid-column: -3 / span 2;

      &:before {
        top: -50px;
      }
    }

    .MainChartPartPopup {
      grid-row: 1 / span 3;
      grid-column: -4 / span 3;
      padding-top: 72px + 40px;
    }
  }
  @media (min-width: $bp-xlg-desktop-min-width) {
    .TopBar {
      padding: 0 var(--page-content-side-padd);
    }
    .TopBar__inner {
      width: 100%;
      max-width: $bp-xlg-desktop-min-width;
    }
    .TopBar__closeBtn {
      margin-right: 300px;
    }

    .ChartView {
      width: 80%;
      height: auto;
      padding-top: 80%;
    }
  }
}
