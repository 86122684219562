@import '../../vars';

.PageTitle {
  @extend %flex;
  align-items: center;
  margin-bottom: 16px;

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
  }
}
.PageSubTitle {
  p {
    margin: 0;
    font-weight: 700;
    line-height: 24px;
  }
}
.PageTitle__backBtn {
  align-self: baseline;
  margin-right: 8px;
  margin-top: 2px;
  min-width: 32px;
}

.Heading2 {
  @include headingText;
  color: $col-blue-d;
  margin: 0;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .PageTitle {
      h1 {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  @media (max-width: $bp-mobile-small-max-width) {
    .PageTitle {
      h1 {
        font-size: 20px;
        line-height: 1.4;
      }
    }
    .PageTitle__backBtn {
      height: 28px;
    }
  }
}
