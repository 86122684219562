.Popover {
  max-width: calc(100% - 32px);
  padding: 16px 0;
  z-index: var(--zindex-popover);

  &:not(._interactive) {
    pointer-events: none;
  }

  &:not(._active) {
    display: none;
  }
  &[x-placement='bottom'] {
    .Popover__arrow {
      top: 0;
      svg {
        transform: rotate(0deg);
        top: unset;
        bottom: -6px;
      }
    }
  }
}
.Popover__arrow {
  margin-left: 4px;
  margin-right: 4px;
  position: absolute;
  svg {
    position: relative;
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
    top: -6px;
  }
}
