@import '../../vars';

@media only screen {
  //@media (max-width: $bp-tablet-max-width) {
  //
  //  .BudgetChart__wrapper {
  //    max-width: none;
  //  }
  //
  //}
  @media (max-width: $bp-mobile-max-width) {
    .BudgetChart__wrapper {
      width: calc(100% + var(--page-content-side-padd) * 2);
    }
  }
}
