@import '../../vars';
@import '../../colors';

.PageOffset {
  position: relative;
  @extend %flex-c;
}

.PageLayout {
  padding-top: calc(var(--top-bar-height) + 24px);
  margin: 0 auto;
  display: grid;
  overflow: auto;
  grid-template-columns: auto 378px;
  grid-template-rows: auto;
  width: 100%;
  position: relative;
}

.IndicatorsPage__title {
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  align-items: center;
}

.IndicatorsPage__title_btn {
  width: 24px;
  height: 24px;
  margin-right: 14px;
  padding: 0;
}

.ProgressViewButton {
  @extend %flex;
  width: 100%;
  align-items: center;
  height: 56px;
  padding: 0 12px;
  border-radius: 10px;
  color: $col-white;
  cursor: pointer;

  --background-color: #{$col-gray};
  background: var(--background-color);

  &:hover,
  &:focus {
    background-color: var(--background-color);
  }

  &:active {
    background-color: var(--background-color);
  }

  &._success {
    --background-color: #{$col-success};
  }

  &._warn {
    --background-color: #{$col-warn};
  }

  &._error {
    --background-color: #{$col-error};
  }
}
.ProgressViewButton__icon {
  @extend %flex-c;
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 6px;
  background: $col-white;

  svg {
    width: 24px;
    height: 24px;
  }
}
.ProgressViewButton__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .IndicatorsPage__ChartView {
      padding-bottom: 16px;
    }
    .BudgetChart__wrapper {
      max-width: none;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .BudgetChart__wrapper {
      width: calc(100% + var(--page-content-side-padd) * 2);
    }

    .IndicatorsPage__title {
      font-size: 24px;
      line-height: 32px;
    }
  }
  @media (max-width: $bp-mobile-small-max-width) {
    .IndicatorsPage__title {
      font-size: 20px;
      line-height: 26px;
    }
  }
}
