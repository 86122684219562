@import '../../vars';

.InfoView {
}
.InfoView__results {
  margin-top: 40px;
}
.InfoView__resultsTitle {
  margin-bottom: 20px;
}

.InfoView__internet {
  background: white;
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;
}

.Internet__legend {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.Internet__computers {
}

.Internet__chart {
  margin-bottom: 16px;
}

.Internet__chartTitle {
  padding-left: 0;
}

.InternetInfoComputer {
  margin-top: 32px;
}

.InternetInfoComputer__title {
  margin-bottom: 4px;
}

.InternetInfoComputer__speed {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  > *:not(:last-child) {
    margin-right: 24px;
  }
}

.Speed {
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 4px;
  }
}

.Speed__icon {
}

.Speed__value {
  font-size: 42px;
  line-height: 50px;
  font-weight: 100;
}

.Speed_down {
  .Speed__icon {
    transform: rotate(90deg);
    color: $col-chart-blue;
  }
}

.Speed_up {
  .Speed__icon {
    transform: rotate(270deg);
    color: $col-chart-blue2;
  }
}
