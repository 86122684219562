@import '../../../vars';

.SubsidiaryView {
  position: relative;
  background: $col-white;
}

.SubsidiaryView__photosSlider {
  height: 408px;
}

.SubsidiaryView__btnScan {
  margin: 18px auto 32px;
  font-weight: 700;
}

.SubsidiaryView__filtersWrapper {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.SubsidiaryView__filters {
  padding: 12px 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: $col-white;
  border-radius: 8px;
  z-index: var(--zindex-subsidview-filters);
}
.SubsidiaryView__filterItem {
  &:not(:first-child) {
    margin-left: 8px;
  }
}
.ResultsInfoView {
  grid-area: 1 / 8 / 4 / 14;
  height: 100%;
  margin-left: 14px;
  background: $col-white;
  width: 375px;
  padding-bottom: 40px;
}

.ChartData {
  background: $col-blue;
  padding: 32px 24px;
}
.Chart__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $col-white;
}

.TotalData__scroll {
  width: 100%;
}
.ChartData__select {
  height: 40px;
  width: 120px;
  margin-top: 14px;
  :global .select__control {
    height: 40px;
  }
}
.ChartData__charts {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.ChartData__chartItem {
  position: relative;
}
.ChartData__chart {
  height: 208px;
}
.ChartData__chartTooltip {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: $col-white;
  white-space: pre;
}
.TotalData {
  margin: 45px 24px 0;
  padding-bottom: 30px;
}
.TotalData__title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.TotalData__select {
  width: 100%;
  margin-top: 18px;
  :global .select__control {
    height: 40px;
  }
}
.TotalData__legends {
  margin-top: 44px;
  display: flex;
}

.TotalData__legends__item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.TotalData__legends__dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
}
.TotalData__legends__title {
  font-size: 12px;
  line-height: 16px;
}

.TotalData__charts {
  margin-top: 20px;
}
.TotalData__chart {
  padding: 18px 0;
  border-top: 1px dashed rgba($col-gray-d, 0.1);
}
.TotalData__chartTitle {
  color: $col-gray-d;
  font-size: 12px;
  line-height: 16px;
}

.SubsidiaryView__externalBtns {
  width: 280px;
  margin: 36px auto 55px;
}

.SubsidiaryView__externalBtn {
  width: 100%;
  margin-top: 16px;
}

:export {
  purpleL: rgba(136, 108, 255, 1);
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .SubsidiaryView__photosSlider {
      height: auto;
    }
  }

  @media (min-width: $bp-lg-desktop-min-width) {
    .SubsidiaryView__photosSlider {
      height: 505px;
    }
  }
}
