@import '../../../colors';

.checkBox {
  width: 100%;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.checkBox__svg {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  color: $col-white;
  &._card {
    width: 24px;
    height: 24px;
    color: $col-blue;
  }
}

.checkBox__input {
  display: none;
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    display: flex;
    margin-right: 8px;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    padding: 0;
    line-height: 16px;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    border: 2px rgba($col-gray-d, 0.5) solid;
    background: $col-white;
    border-radius: 2px;
  }

  // Box hover
  &:hover + label:before {
    border: none;
    background: rgba($col-gray-d, 0.5);
  }
  // Box hover
  &:hover:disabled + label:before {
    border: 2px rgba($col-gray-d, 0.5) solid;
    background: $col-white;
  }

  // Box hover
  &:hover:disabled:checked + label:before {
    border: none;
    background: $col-chart-blue;
  }

  // Box hover
  &:hover:disabled:indeterminate + label:before {
    border: none;
    background: $col-chart-blue;
  }

  // Box checked
  &:checked + label:before {
    border: none;
    background: $col-chart-blue;
  }

  // Box indeterminate check
  &:indeterminate + label:before {
    border: none;
    background: $col-chart-blue;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    opacity: 0.5;
  }
}

.checkBox__card {
  & + label {
    align-items: center;
    min-height: 72px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  // Box.
  & + label:before {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    border: none;
    background: $col-white;
    border-radius: 0;
    min-width: 24px;
  }

  // Box hover
  &:hover + label:before {
    color: $col-blue;
    background: none;
  }
  // Box hover
  &:hover:disabled + label:before {
    border: none;
  }

  // Box hover
  &:hover:disabled:checked + label:before {
    background: none;
  }

  // Box hover
  &:hover:disabled:indeterminate + label:before {
    background: none;
  }

  // Box checked
  &:checked + label:before {
    background: none;
  }

  // Box indeterminate check
  &:indeterminate + label:before {
    background: none;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    opacity: 0.5;
  }
}
