@import '../../../vars';
@import '../../../colors';

$col-secondary-hover: rgb(233, 234, 237);

.Button {
  @extend %button-reset;

  display: block;
  position: relative;
  width: 224px;
  height: var(--button-height);
  padding: 1px 7px;
  border: none;
  border-radius: 8px;
  background-color: $col-chart-blue;
  text-align: center;

  font-family: inherit;
  font-size: 14px;
  text-decoration: none;
  color: $col-white;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: $col-blue;
  }

  &:active {
    background-color: lighten($col-blue, 10%);
  }

  &:disabled {
    opacity: 0.6;
    &:hover,
    &:focus {
      background-color: $col-chart-blue;
    }
  }

  &._link {
    @extend %flex;
    align-items: center;
    justify-content: center;
  }
  &._withIcon {
    text-align: left;
    padding-left: 20px;
    padding-right: 24px + 12px + 7px;

    i {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      color: $col-white;

      & > svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &.Button_secondary {
      i {
        color: $col-chart-blue;
      }
    }
  }
}

.Button_darker {
  color: $col-chart-blue;
  background-color: $col-darker;

  &:hover,
  &:focus {
    background-color: darken($col-darker, 3%);
  }

  &:active {
    background-color: darken($col-secondary-hover, 10%);
  }

  &:disabled {
    opacity: 0.6;
    &:hover,
    &:focus {
      background-color: $col-darker;
    }
  }
}

.Button_secondary {
  color: $col-chart-blue;
  background-color: $col-light-background;

  &:hover,
  &:focus {
    background-color: $col-secondary-hover;
  }

  &:active {
    background-color: darken($col-secondary-hover, 10%);
  }

  &:disabled {
    opacity: 0.6;
    &:hover,
    &:focus {
      background-color: $col-light-background;
    }
  }
}

.Button_card {
  width: 100%;
  min-height: 72px;
  color: $col-blue-d;
  background: none;
  border: none;
  border-top: 1px solid rgba($col-gray-d, 0.1);

  &:last-child {
    border-bottom: 1px solid rgba($col-gray-d, 0.1);
  }

  &:hover,
  &:focus {
    color: $col-blue;
    background: none;
  }

  &:active {
    color: darken($col-blue, 10%);
    background: none;
  }

  &:disabled {
    opacity: 0.6;
    &:hover,
    &:focus {
      background: none;
    }
  }
}

.Button__loader {
  width: 100%;
  height: 2px;
  position: absolute !important;
  bottom: 0;
  left: -2px;
  border-radius: 4px;
}
