@import '../../colors';

.UserInfo {
  text-align: center;
}

.UserInfo__name {
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  line-height: (38 / 28);
}

.UserInfo__description {
  margin: 0;
  color: $col-gray-d;
  font-size: 14px;
  line-height: (18 / 14);
}

.LogoutButton {
  margin-top: 8px;
  height: 56px;
  width: 100%;
  font-weight: 700;
}

.ClearCacheButton {
  margin-top: 36px;
  width: 100%;
}