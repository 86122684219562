@import '../../vars';

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-100Italic.woff2') format('woff2'),
    url('MuseoSansCyrl-100Italic.woff') format('woff'),
    url('MuseoSansCyrl-100Italic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-500Italic.woff2') format('woff2'),
    url('MuseoSansCyrl-500Italic.woff') format('woff'),
    url('MuseoSansCyrl-500Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-900Italic.woff2') format('woff2'),
    url('MuseoSansCyrl-900Italic.woff') format('woff'),
    url('MuseoSansCyrl-900Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-300.woff2') format('woff2'),
    url('MuseoSansCyrl-300.woff') format('woff'),
    url('MuseoSansCyrl-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-100.woff2') format('woff2'),
    url('MuseoSansCyrl-100.woff') format('woff'),
    url('MuseoSansCyrl-100.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-500.woff2') format('woff2'),
    url('MuseoSansCyrl-500.woff') format('woff'),
    url('MuseoSansCyrl-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-900.woff2') format('woff2'),
    url('MuseoSansCyrl-900.woff') format('woff'),
    url('MuseoSansCyrl-900.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-700Italic.woff2') format('woff2'),
    url('MuseoSansCyrl-700Italic.woff') format('woff'),
    url('MuseoSansCyrl-700Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-700.woff2') format('woff2'),
    url('MuseoSansCyrl-700.woff') format('woff'),
    url('MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $font-museo;
  src: url('MuseoSansCyrl-300Italic.woff2') format('woff2'),
    url('MuseoSansCyrl-300Italic.woff') format('woff'),
    url('MuseoSansCyrl-300Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
