@import '../../../colors';

.TextInputQuestions__input {
  padding-top: 16px;
  &:focus + .TextInputQuestions__question {
    font-size: 12px;
    top: 10px;
  }
}

.TextInputQuestions__question {
  position: absolute;
  opacity: 0.5;
  color: $col-gray-d;
  transition: font-size 0.1s ease, top 0.1s ease;
  left: 20px;
  font-size: 14px;
  top: 20px;
  line-height: 18px;
  user-select: none;
  pointer-events: none;

  &._hasValue {
    font-size: 12px;
    top: 10px;
  }
}
