@import '../../../vars';

.Types {
  margin-top: 46px;
}
.Types__title {
  margin-bottom: 8px;
}

.Chart {
  position: relative;
}
.Types__filter {
  margin-bottom: 40px;
}
.Types__select {
  width: 100%;
}
.Chart__view {
  position: relative;
}
.Chart__chart {
  width: 100%;
  height: 100%;
}
.Chart__info {
  @extend %flex-c;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.Chart__infoContent {
  text-align: center;
  margin-top: -4px;
  pointer-events: auto;
  p {
    margin: 0;
  }
}
.Chart__infoValue {
  p {
    font-weight: 300;
    font-size: 42px;
    line-height: 50px;
  }
}
.Chart__infoUnits {
  color: $col-gray-d;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
}

.ChartLegend {
  margin-top: 26px;
}
.ChartLegend__item {
  @extend %flex;
  //align-items: baseline;
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }
}
.ChartLegend__itemDot {
  width: 6px;
  min-width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 12px;
}
.ChartLegend__itemTitle {
  width: 100%;
  color: $col-gray-d;
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
.ChartLegend__itemValue {
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
  }
}
