@import '../../../colors';
@import '../../../vars';

$adaptiveRatio: 1;
$containerWidth: 630px * $adaptiveRatio;
$outerDonutPieChart: 350px * $adaptiveRatio;
$donutPieChart: 300px * $adaptiveRatio;
$innerDonutPieChart: 200px * $adaptiveRatio;
$PlanetChart: 156px * $adaptiveRatio;

.ChartView {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  &._loading {
    .ChartView__content {
      opacity: 0.4;
    }
  }
}
.ChartView__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--zindex-chartview-loader);
}
.ChartView__content {
}

.ChartView__planets {
  position: absolute;
  z-index: var(--zindex-chartview-planets);
  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 1s ease, filter 0.2s ease;
}
.ChartView__planet {
  transition: transform 2s;
  //width: $PlanetChart;
  //height: $PlanetChart;
  position: absolute;
  cursor: pointer;
  z-index: var(--zindex-chartview-planet);
  pointer-events: auto;
}
.ChartView__planetLine {
  width: 100%;
  height: 100%;
}
.ChartView__planetShadow {
  position: absolute;
  border-radius: 50%;
  margin-top: 10px;
  margin-left: 10px;
  opacity: 0.3;
  filter: blur(30px);
  pointer-events: none;
  will-change: filter, transform;
}

.ChartView__donutPieCharts {
  position: absolute;
  z-index: var(--zindex-chartview-piecharts);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ChartView__donutPieChart {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  pointer-events: auto;

  path {
    transition: opacity 0.2s;
  }
}

.ChartView__title {
  position: absolute;
  z-index: var(--zindex-chartview-title);
  text-align: center;
  font-size: 32px;
  line-height: 22px;
  color: $col-white;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  > div {
    pointer-events: auto;
  }
}

.ChartView__unit {
  margin-top: 6px;
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 12px;
  color: $col-gray-d;
}

.SubjectHistogram {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s ease, filter 0.2s ease;

  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.ChartView__HistogramSection {
  pointer-events: auto;
}

:export {
  chartLightBlue: rgba(54, 151, 249, 1);
  chartLightBlue2L: rgba(45, 126, 210, 1);
  chartBlue2L: rgba($col-chart-blue2, 0.6);
  chartAqua: rgba(0, 238, 255, 1);
  chartAqua2: rgba(0, 238, 255, 1);
}

@media only screen {
  @media (max-width: $bp-tablet-land-max-width) {
    .ChartView__title {
      font-size: 24px;
    }
  }
  @media (max-width: $bp-tablet-max-width) {
    .ChartView {
      width: 580px;
      height: 580px;
    }
    .ChartView__title {
      font-size: 28px;
    }
  }
  @media (max-width: $bp-mobile-max-width) {
    .ChartView {
      width: 100%;
      height: auto;
      padding-top: 100%;
    }
    .ChartView__title {
      font-size: 18px;
      line-height: 16px;
    }
  }
}
