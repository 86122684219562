@import '../../../vars';

.Goals {
  position: fixed;
  width: var(--right-pane-width);
  overflow-y: scroll;
  top: 72px;
  bottom: 0;
  left: 100%;
  background-color: $col-white;
  transition: left 0.2s ease;
  z-index: var(--zindex-goals);
  padding: 42px 22px;
  -webkit-transform: translate3d(0, 0, 0);

  &._active {
    left: calc(100% - var(--right-pane-width));
  }
}

.backgroundWrapper {
  position: fixed;
  background-color: rgba($col-black, 0.1);
  pointer-events: none;
  cursor: pointer;
  z-index: var(--zindex-bg-wrapper);
  opacity: 0;
  top: 72px;
  left: 72px;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s ease;
  display: none;

  &._active {
    display: block;
    pointer-events: auto;
    z-index: var(--zindex-bg-wrapper-active);
    opacity: 1;
  }
}

.Goals__close {
  position: absolute;
  top: calc(22px - (32px - 19px) / 2);
  right: calc(22px - (32px - 19px) / 2);
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .backgroundWrapper {
      display: none !important;
    }
  }
}
