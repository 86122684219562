@import '../../vars';
//@import "~react-responsive-carousel/lib/styles/carousel.min.css";

.ImageSlider {
  position: relative;
}

.ImageSlider__slider {
  & :global {
    .carousel {
      .control-arrow {
        @extend %flex-c;
        top: 50%;
        bottom: auto;
        padding: 0;
        width: 32px;
        height: 32px;
        margin-top: -16px;
        border-radius: 50%;
        background: $col-white;
        opacity: 0;
        transition: opacity 0.3s;

        &:before {
          width: 24px;
          height: 24px;
          margin: 0;
          border: none;
          background: url(../../assets/img/icons/i-arr-left.svg) no-repeat 0 0;
          background-size: contain;
        }

        &.control-prev {
          margin-left: 20px;
        }
        &.control-next {
          margin-right: 20px;
          opacity: 0.5;

          &:before {
            transform: scale(-1, 1);
          }
        }

        &:hover {
          background: $col-white;
          opacity: 0.8 !important;
        }
      }

      &:hover {
        .control-arrow {
          opacity: 1;
        }
      }
    }
  }
}
.ImageSlider__status {
  position: absolute;
  top: 37px;
  left: 44px;
  color: $col-white;
  display: flex;

  p {
    margin: 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
}
.ImageSlider__actualityDate {
  margin-left: 16px !important;
  color: rgba($col-white, 0.6);
}
