@import '../../../vars';
@import '../../../colors';

.roadmapCards {
  margin: 0 var(--right-pane-side-padd) 16px;
}

.roadmapCards__title {
  margin-bottom: 16px;
  color: $col-blue-d;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.roadmapCard {
  @extend %link-reset;
  margin: 16px 0;
  padding: 24px 16px;
  background-color: $col-gray-l;
  border-radius: 8px;
  &:hover {
    background-color: darken($col-gray-l, 10%);
  }
}

.roadmapCard__title {
  display: flex;
  font-size: 14px;
  line-height: 20px;
}

.roadmapCard__number {
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 50%;
  color: $col-gray-d;
  background-color: rgba($col-gray-d, 0.1);
  font-size: 12px;
  font-weight: 700;
}

.roadmapCard__charts {
  > * {
    margin-top: 16px;
  }
}
