@import '../../vars';

.ObjectView {
  background: $col-white;
  //overflow: hidden;
  //overflow-y: auto;
}
.ObjectView__photosSlider {
  height: 408px;
}
.ObjectView__tags {
  margin-top: 30px;
}
.ObjectView__tag {
  font-weight: 700;
  font-size: 12px;
}

.ObjectView__separator {
  margin: 26px 0 22px;
}

@media only screen {
  @media (max-width: $bp-tablet-max-width) {
    .ObjectView__photosSlider {
      height: auto;
    }
  }

  @media (min-width: $bp-lg-desktop-min-width) {
    .ObjectView__photosSlider {
      height: 505px;
    }
  }
}
