@import '../../../vars';

.Inventory {
}

.Inventory__title {
  margin-bottom: 18px;
}

.Inventory__scroll {
  margin: 0 invert(var(--regionpage-about-hor-padd));
}
.Inventory__list {
  @extend %flex;
  align-items: flex-start;
  padding: 0 var(--regionpage-about-hor-padd);
  overflow: hidden;
  overflow-x: auto;
}
.Inventory__item {
  min-width: 248px;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  p {
    margin: 0;
  }
}
.Inventory__itemValue {
  margin-bottom: 8px;
  p {
    font-weight: 300;
    font-size: 42px;
    line-height: 50px;
  }
}
.Inventory__itemDesc {
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
.Inventory__actualityDate {
  margin-top: 8px;
}

.Inventory__scrollWrapper {
  margin-left: calc(-1 * var(--right-pane-side-padd));
  margin-right: calc(-1 * var(--right-pane-side-padd));

  :global .__scrollListWrapper {
    padding: 0 var(--right-pane-side-padd);
  }
}
