@import '../../vars';

.StatusView {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;
}
.StatusView__item {
  margin-top: 4px;
  width: 84px;
}
.StatusView__number {
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
}
.StatusView__title {
  color: $col-gray-d;
  font-size: 12px;
}

.ReportTypeTable {
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.Report__Content {
  grid-area: 2 / 1 / 3 / 1;
  overflow: auto;

  &._text,
  &._graphical {
    padding-right: 16px;
    margin-right: calc(-1 * var(--page-content-side-padd));
  }
}

.RoadmapEventsList,
.ControlPointsList {
  margin: 0;
  padding: 0;
}

.RoadmapEvent {
  margin: 0 0 32px;
  padding: 0;
  list-style: none;
}

.RoadmapEvent__status {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  font-size: 10px;
  height: auto;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.RoadmapEvent__text {
  margin: 0 0 4px;
  font-size: 12px;
  line-height: 16px;
  &:last-child {
    margin: 0;
  }
}

.ControlPointsList {
  margin-bottom: 48px;
  &:last-child {
    margin-bottom: 0;
  }
}

.ControlPoint {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  &:last-child {
    margin-bottom: 0;
  }
}

.ControlPoint__text {
  margin: 0 0 4px;
  font-size: 14px;
  line-height: 20px;
  &:last-child {
    margin: 0;
  }
}

.InfoGroup {
  max-width: 530px;
  margin-bottom: 56px;
}

.InfoGroup__title {
  margin: 0 0 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.project {
}
.project__name {
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
  margin-bottom: 24px;
}
.project__roadmapEventsList {
}
.roadmapEventsList {
  border-bottom: 1px solid rgba($col-gray-d, 0.1);
  margin-bottom: 32px;
}
.roadmapEventsList__name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}
.roadmapEventsList__title {
  color: $col-gray-d;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  font-weight: 700;
}
.roadmapEventsList__list {
  font-size: 12px;
  line-height: 16px;
  padding: 0;
}

.ReportGeneratorField {
  margin-bottom: 24px;
}

.ReportGeneratorField__label {
  margin: 0 0 4px;
  color: $col-gray-d;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.ReportGeneratorField__value {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  > p {
    margin: 0;
  }
}

.ButtonGroup {
  margin-top: auto;
}

.ButtonGroup__btn {
  margin: 0 auto 16px;
  width: 280px;
  &:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: $bp-mobile-max-width) {
  .ButtonGroup__btn {
    width: 240px;
  }
}

._success {
  color: $col-success;
}
._warn {
  color: $col-warn;
}
._error {
  color: $col-error;
}
._default {
  color: $col-black;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .Report__Content {
      &._graphical {
        padding-left: var(--page-content-side-padd);
        padding-right: var(--page-content-side-padd);
        margin-left: calc(-1 * var(--page-content-side-padd));
        margin-right: calc(-1 * var(--page-content-side-padd));
      }
    }
  }

  @media (min-width: $bp-lg-desktop-min-width) {
    //.Report__Content {
    //  &._text, &._graphical {
    //    padding-right: 0;
    //    margin-right: 0;
    //  }
    //}
  }
}
