@import '../../vars';

.FilterMenu {
  pointer-events: auto;
  color: $col-gray-d;
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 14px;
  width: 130px;
  position: relative;
  margin-bottom: 16px;
}

.FilterMenu__svg {
  width: 18px;
  height: 18px;
  margin-left: 15px;
}

.FilterMenu__count {
  @extend %flex-c;
  position: absolute;
  top: 4px;
  right: 6px;
  width: 16px;
  height: 16px;
  background-color: $col-error;
  border-radius: 50%;
  font-size: 8px;
  font-weight: 700;
  color: $col-white;
}

.ModalFilterMenu {
  position: fixed;
  z-index: var(--zindex-map-filters-popup);
  top: 0;
  left: var(--nav-bar-width);
  right: 0;
  bottom: 0;
  padding: 0 var(--page-content-side-padd);
  background: $col-white;
  overflow-y: auto;
}

.ModalFilterMenu__content {
  margin: 24px auto 152px;
  max-width: 395px;
}
.ModalFilterMenu__PageTitle {
  position: relative;
  width: calc(100% + 40px);
  left: -40px;
}

.ModalFilterMenu__button {
  position: fixed;
  bottom: 24px;
  margin: 0 auto;
  left: calc(50% - 140px + 36px + (var(--nav-bar-width) / 2));
}

@media (max-width: $bp-mobile-max-width) {
  .ModalFilterMenu {
    left: 0;
  }

  .ModalFilterMenu__PageTitle {
    left: 0;
  }

  .ModalFilterMenu__button {
    left: calc(50% - 140px + 36px);
  }
}
