@import '../../vars';

$col-primary-hover: darken($col-light-background, 3%);
$col-secondary: rgb(70, 97, 193);
$col-secondary-hover: lighten($col-secondary, 3%);

//@keyframes kbControlHl {
//  0% {
//    outline: 2px solid $col-gray-d;
//  }
//  100% {
//    outline: 2px solid transparent;
//  }
//}

.DatePicker {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  user-select: none;

  &._primary {
    color: $col-blue-d;

    .DatePicker__input,
    .DatePicker__dropdown {
      background: $col-light-background;
    }

    .DatePicker__input {
      &:hover,
      &:active,
      &:focus,
      &._active {
        background: $col-primary-hover;
        .DatePicker__arrow {
          color: inherit;
        }
      }
    }
    .DatePicker__arrow {
      color: $col-gray-d;
    }
  }
  &._secondary {
    color: rgba($col-white, 0.6);

    .DatePicker__input,
    .DatePicker__dropdown {
      background: $col-secondary;
    }
  }
  &._menu {
    .DatePicker__dropdown {
      background: $col-light-background;
    }
  }

  &._yeartab {
    .DatePicker__head {
      padding-left: 10px;
    }
  }

  &._focused {
    &._primary {
      .DatePicker__input {
        background: $col-primary-hover;
        .DatePicker__arrow {
          color: inherit;
        }
      }
    }
  }
}

.DatePicker__input {
  position: relative;
  @extend %flex-btw;
  min-width: 112px;
  height: 40px;
  padding-left: 12px;
  padding-right: 6px;
  border-radius: 8px;
  white-space: nowrap;
  cursor: pointer;
  transition: background 0.2s;
}
.DatePicker__arrow {
  @extend %svg-icon;
  width: 24px;
  height: 24px;
  color: inherit;
  transition: color 0.2s;
}
.DatePicker__hiddenInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
  //pointer-events: none;
}

.DatePicker__dropdown {
  position: absolute;
  z-index: var(--zindex-select-menu);
  top: 100%;
  left: 0;
  //width: 180px;
  padding: 16px 10px;
  margin-top: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba($col-blue-d, 0.1);
}
.DatePicker__head {
  @extend %flex-btw;
  padding: 0 10px;
  padding-left: 13px;
  margin-bottom: 8px;
}
.DatePicker__value {
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
}
.DatePicker__btns {
  @extend %flex;
  align-items: center;
  padding: 2px 4px;
  margin: -2px -4px -2px 0;
  border: 2px solid transparent;
  border-radius: 8px;

  &._focused {
    //animation: kbControlHl 1s linear forwards;
    border-color: $col-gray-d;
  }
}
.DatePicker__btn {
  @extend %flex-c;
  @extend %svg-icon;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }

  &._next {
    transform: scale(-1, 1);
    margin-left: 6px;
  }
  &._disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.DatePicker__sheet {
  width: 156px;
  table {
    width: 100%;
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
  }
}
.DatePicker__sheetItem {
  padding: 6px 0;
  border: 2px solid transparent;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: background 0.2s, color 0.2s, opacity 0.2s;

  &._active {
    background: $col-chart-blue;
    border-color: $col-chart-blue;
    color: $col-white;
  }
  &:not(._active) {
    &:hover {
      opacity: 0.7;
    }
  }

  &._focused {
    background: none;
    border-color: $col-gray-d;
    color: $col-blue-d;
  }
}
