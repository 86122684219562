@import '../../../colors';
@import '../../../vars';

._success {
  color: $col-success;
}
._warn {
  color: $col-warn;
}
._error {
  color: $col-error;
}
._default {
  color: $col-black;
}

.Card {
  font-family: $fonts-default;
  width: 279px;
  padding: 16px;
  border-radius: 12px;
  background-color: $col-white;
  position: relative;
  display: flex;
  flex-direction: column;
}

.Card__status {
  letter-spacing: 0.4px;
  font-size: 10px;
  margin-bottom: 12px;
}

.Card__svg {
  width: 12px;
  height: 12px;
}

.Card__article {
  //color: $col-blue-d;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  //min-height: 102px;
  flex-grow: 1;
}

.Card__article._small {
  min-height: 46px;
}

.Card__charts {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  grid-auto-flow: row;
  grid-gap: 24px;
}

.Card__description {
  display: flex;
  font-family: $fonts-default;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
}

.Card__title {
  width: calc(100% - 45px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 18px;
  padding-right: 4px;
}

.Card__percent {
  font-weight: bold;
}

.Card__chart {
}

.Card__chartLine {
  margin-top: 8px;
}

.Card__chartLineFill {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
}

.chartLine {
  width: 100%;
  height: 4px;
  background: $col-light-background;
  position: relative;

  &._success .Card__chartLineFill {
    background-color: $col-success;
  }
  &._warn .Card__chartLineFill {
    background-color: $col-warn;
  }
  &._error .Card__chartLineFill {
    background-color: $col-error;
  }
  &._default .Card__chartLineFill {
    background-color: $col-black;
  }
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .Card {
      border-radius: 0;
    }
  }
}
