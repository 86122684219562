@import '../../../vars';

.MapFilters {
  margin-top: -16px;
}
.MapFilters__filter {
  @extend %flex;
  align-items: center;

  pointer-events: auto;
  margin-top: 16px;
  margin-right: 16px;
  vertical-align: top;

  &:last-child {
    margin-right: 0;
  }
}
.MapFilters__filterSelect {
  width: 240px;
  color: $col-gray-d;
}

.MapFilters__popupSelect {
  width: 100%;
}

.MapFilters__btn {
  pointer-events: auto;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  color: $col-gray-d;
  @extend %flex-c;
}

.MapFilters__mobileFiltersBtn {
  @extend %flex;
  align-items: center;

  pointer-events: auto;
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 0 !important;
  vertical-align: top;
}

.MapFilters__btnActive {
  color: $col-chart-purple;
}

.MapFilters__pane {
  padding: 8px 12px;
  background: white;
  border-radius: 8px;
}

.MapFilters__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.MapFilters__actualityDate {
  margin-left: 16px;
}

@media only screen {
  @media (max-width: $bp-mobile-max-width) {
    .MapFilters__filter {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    .MapFilters__filterSelect {
      width: 100%;
    }
  }
}
