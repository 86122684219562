@import '../../../vars';

.SubjectInfo {
  margin-top: 40px;
}
.SubjectInfo__title {
  margin-bottom: 16px;
}
.SchoolsInfo {
  margin-top: 40px;
}
.SchoolsInfo__countSmallSchools {
  font-size: 42px;
  line-height: 50px;
  font-weight: 300;
}
