@import '../../../colors';

.IconButton {
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: $col-black;
  padding: 4px;
  text-decoration: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    color: $col-chart-purple;
  }

  &:active {
    color: lighten($col-chart-purple, 10%);
  }
}

.IconButton__icon {
  width: 100%;
  height: 100%;
}

.IconButton__text {
  display: block;
  margin: 0;
  vertical-align: sub;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
