@import '../../vars';

.ProgressBar__title {
  @extend %flex-btw;
  align-items: baseline;
  margin-bottom: 8px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.ProgressBar__value {
  margin-left: 8px;
  span {
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
}

.ProgressBar__bar {
  position: relative;
  height: 4px;
  background: $col-gray;
}
.ProgressBar__barValue {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 100%;

  &._error {
    background: $col-error;
  }
  &._warn {
    background: $col-warn;
  }
  &._success {
    background: $col-success;
  }
}
