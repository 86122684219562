@import '../../colors';

.Watermark {
  position: fixed;
  z-index: 10000;
  top: 72px;
  left: 72px;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;
  div {
    font-size: 24px;
    color: rgba($col-gray-l, 0.05);
    text-shadow: 0 0 0 rgba($col-blue-d2, 0.1);
    padding: 72px 40px;
    transform: rotate(320deg);
  }
}
